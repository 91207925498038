import { PaymentService, EventService, PromoterEventService, AddonService, TickerBookingService } from '@services';

export default {
  methods: {
    async $login(response) {
      localStorage.setItem('auth.token', response.token);
      this.$store.set('auth/user', response.user);
      this.$store.set('app/state', {});

      this.$emit('close');
      this.$root.$emit('login');

      if (['promoter.login', 'visitor.login', 'visitor.dashboard'].includes(this.$route.name)) {
        if (response.user.role === 'admin') {
          this.$next({ name: 'admin.dashboard' });
        } else if (response.user.role === 'promoter') {
          let accountId = '';
          if (response.user && response.user.stripe && response.user.stripe.accountId) {
            localStorage.setItem('account_id', response.user.stripe.accountId);
            accountId = response.user.stripe.accountId;
          } else if(response.user && (!response.user.stripe || (response.user.stripe && !response.user.stripe.accountId))) {
            const createConnectedAccountResp = await PaymentService.createConnectedAccount(response.user._id);
            if(createConnectedAccountResp.statusCode === 200) {
              localStorage.setItem('account_id', createConnectedAccountResp.stripeAccount.id);
              accountId = createConnectedAccountResp.stripeAccount.id;
            }
          }
          if(accountId !== '') {
            const striperesponse = await PaymentService.getAccountInfo(accountId);
            const stripeLinkresponse = await PaymentService.getStripeLink(accountId);
            this.showKYCNotification(striperesponse, stripeLinkresponse);
          }
          this.$next({ name: 'promoter.dashboard' });
        } else if (response.user.role === 'venue') {
          this.$next({ name: 'venue.events' });
        } else {
          this.$next({ name: 'visitor.dashboard' });
        }
      }
    },

    async $logout() {
      let userId = this.$user._id || '';
      this.$root.$emit('emptySeat');
      this.$root.$emit('stop-timer');
      EventService.$emit('close-snackbar');
      const seats = JSON.parse(window.localStorage.getItem('selectedSeats') || '[]');
      if (seats && seats.length > 0) {
        const data = [];
        const times = [];
        for (let index = 0; index < seats.length; index++) {
          const element = seats[index];
          const findIndex = data.findIndex(d => d.id === element._id);
          times.push(element.time);
          if (findIndex === -1) {
            data.push({
              id: element._id,
              bucketTickets: 1,
            });
          } else {
            data[findIndex].bucketTickets = data[findIndex].bucketTickets + 1;
          }
        }
        await TickerBookingService.removeFromCart({ eventTicketCategoryIds: data, userId, times });

      }
      const selectedAddson = JSON.parse(window.localStorage.getItem('selectedAddson'));
      const eventId = window.localStorage.getItem('eventId')
      if (selectedAddson && selectedAddson.length && eventId) {
        const data = [];
        const times = [];
        for (let index = 0; index < selectedAddson.length; index++) {
          const element = selectedAddson[index];
          times.push(element.time);
          data.push({
            id: element.addon._id,
            bucketTickets: 1,
            attributeId: element.selectedAttribute.id
          });
        }
        await AddonService.releaseTicket({ addsonsIds: data, event: eventId, times})
        window.localStorage.removeItem('selectedAddson');
      }
      window.localStorage.removeItem('bookedEvent');
      localStorage.removeItem('auth.token');
      localStorage.removeItem('account_id');
      window.localStorage.removeItem('selectedSeats');
      localStorage.removeItem("user_"+userId);
      this.$store.set('auth/user', null);
      this.$next('/');
    },
  },
};
