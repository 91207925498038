export default [
  // {
  //   path: '/admin/login',
  //   name: 'admin.login',
  //   component: () => import(/* webpackChunkName: "about" */ './views/auth/login.vue'),
  // },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ './layout.vue'),
    children: [
      {
        path: '/',
        name: 'admin.root',
        redirect: '/admin/dashboard',
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: () => import(/* webpackChunkName: "admin.dashboard" */ './views/dashboard/index.vue'),
      },
      {
        path: 'dashboard/transactions',
        name: 'admin.dashboard.transactions',
        component: () => import(/* webpackChunkName: "admin.dashboard" */ './views/dashboard/transactions-full.vue'),
      },
      {
        path: 'subscription',
        name: 'admin.subscription',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/subscription/list.vue'),
        meta: {
          title: 'Subscriptions',
          icon: 'mdi-account-convert-outline',
          breadcrumbs: [
            {
              text: 'Subscription List',
              disabled: false,
              to: '/admin/subscription',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'users',
        name: 'admin.users',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/users/list.vue'),
        meta: {
          title: 'Users',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'User List',
              disabled: false,
              to: '/admin/users',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'users/new',
        name: 'admin.users.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/users/edit.vue'),
        meta: {
          title: 'Users',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'User List',
              disabled: false,
              to: '/admin/users',
              exact: true,
            },
            {
              text: 'Add User',
              disabled: false,
              to: '/admin/users/new',
            },
          ],
        },
      },
      {
        path: 'users/edit/:id?',
        name: 'admin.users.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/users/edit.vue'),
        meta: {
          title: 'Users',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'User List',
              disabled: false,
              to: '/admin/users',
              exact: true,
            },
            {
              text: 'Edit User',
              disabled: false,
              to: '/admin/users/edit',
            },
          ],
        },
      },
      {
        path: 'promo-codes',
        name: 'admin.promo-codes',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/list.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promo Code List',
              disabled: false,
              to: '/admin/promo-codes',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'promo-codes/new',
        name: 'admin.promo-codes.add',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/edit.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promo Code List',
              disabled: false,
              to: '/admin/promo-codes',
              exact: true,
            },
            {
              text: 'Add Promo Code',
              disabled: false,
              to: '/admin/promo-codes/new',
            },
          ],
        },
      },
      {
        path: 'promo-codes/edit/:id',
        name: 'admin.promo-codes.edit',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/edit.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promocode List',
              disabled: false,
              to: '/admin/promo-codes',
              exact: true,
            },
            {
              text: 'Edit Promocode',
              disabled: false,
              to: '/admin/promo-codes/edit',
            },
          ],
        },
      },
      {
        path: 'events/dashboard/:id',
        name: 'admin.events.dashboard',
        component: () => import(/* webpackChunkName: "admin.events" */ '@promoter/views/events/dashboard/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
            {
              text: 'Event Details',
              disabled: false,
              to: '/admin/events/dashboard',
            },
          ],
        },
        props: { fromAdmin: true },
      },
      {
        path: 'events/callover-list/:id',
        name: 'admin.events.callover-list',
        component: () => import(/* webpackChunkName: "admin.events" */ './views/events/callover-list.vue'),
        meta: {
          title: 'Event',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
            {
              text: 'Callover List',
              disabled: false,
              to: '/admin/events/callover-list',
            },
          ],
        },
      },
      {
        path: 'events/survey-report/:id',
        name: 'admin.events.survey-report',
        component: () => import(/* webpackChunkName: "admin.events" */ './views/events/survey-report.vue'),
        meta: {
          title: 'Event',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
            {
              text: 'Survey Report',
              disabled: false,
              to: '/admin/events/survey-report',
            },
          ],
        },
      },
      {
        path: 'events',
        name: 'admin.events',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/events/list.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'events/new',
        name: 'admin.events.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ '@promoter/views/events/edit/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Events List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
            {
              text: 'Add Event',
              disabled: false,
              to: '/admin/events/new',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'events/edit/:id?',
        name: 'admin.events.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ '@promoter/views/events/edit/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Events List',
              disabled: false,
              to: '/admin/events',
              exact: true,
            },
            {
              text: 'Edit Event',
              disabled: false,
              to: '/admin/events/edit',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'performers',
        name: 'admin.performers',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/performers/list.vue'),
        meta: {
          title: 'Performers',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Performers List',
              disabled: false,
              to: '/admin/performers',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'performers/new',
        name: 'admin.performers.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/performers/edit.vue'),
        meta: {
          title: 'Performers',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Performers List',
              disabled: false,
              to: '/admin/performers',
              exact: true,
            },
            {
              text: 'Add Performer',
              disabled: false,
              to: '/admin/performers/new',
            },
          ],
        },
      },
      {
        path: 'performers/edit/:id?',
        name: 'admin.performers.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/performers/edit.vue'),
        meta: {
          title: 'Performers',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Performers List',
              disabled: false,
              to: '/admin/performers',
              exact: true,
            },
            {
              text: 'Edit Performer',
              disabled: false,
              to: '/admin/performers/edit',
            },
          ],
        },
      },
      {
        path: 'event-series',
        name: 'admin.event-series',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/event-series/list.vue'),
        meta: {
          title: 'Event Series',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Series List',
              disabled: false,
              to: '/admin/event-series',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'event-series/new',
        name: 'admin.event-series.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/event-series/edit.vue'),
        meta: {
          title: 'Event Series',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Series List',
              disabled: false,
              to: '/admin/event-series',
              exact: true,
            },
            {
              text: 'Add Event Series',
              disabled: false,
              to: '/admin/event-series/new',
            },
          ],
        },
      },
      {
        path: 'event-series/edit/:id?',
        name: 'admin.event-series.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/event-series/edit.vue'),
        meta: {
          title: 'Event Series',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Series List',
              disabled: false,
              to: '/admin/event-series',
              exact: true,
            },
            {
              text: 'Edit Event Series',
              disabled: false,
              to: '/admin/event-series/edit',
            },
          ],
        },
      },
      {
        path: 'event-type',
        name: 'admin.event-type',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/event-type/list.vue'),
        meta: {
          title: 'Event Type',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Type List',
              disabled: false,
              to: '/admin/event-type',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'event-type/edit/:id?',
        name: 'admin.event-type.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/event-type/edit.vue'),
        meta: {
          title: 'Event Type',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Type List',
              disabled: false,
              to: '/admin/event-type',
              exact: true,
            },
            {
              text: 'Create new event type',
              disabled: false,
              to: '/admin/event-type/edit',
            },
          ],
        },
      },
      {
        path: 'event-category',
        name: 'admin.event-category',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/event-category/list.vue'),
        meta: {
          title: 'Event Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Category List',
              disabled: false,
              to: '/admin/event-category',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'event-category/new',
        name: 'admin.event-category.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/event-category/edit.vue'),
        meta: {
          title: 'Event Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Category List',
              disabled: false,
              to: '/admin/event-category',
              exact: true,
            },
            {
              text: 'Add Event Category',
              disabled: false,
              to: '/admin/event-category/new',
            },
          ],
        },
      },
      {
        path: 'event-category/edit/:id?',
        name: 'admin.event-category.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/event-category/edit.vue'),
        meta: {
          title: 'Event Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Category List',
              disabled: false,
              to: '/admin/event-category',
              exact: true,
            },
            {
              text: 'Edit Event Category',
              disabled: false,
              to: '/admin/event-category/edit',
            },
          ],
        },
      },
      {
        path: 'event-sub-category',
        name: 'admin.event-sub-category',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/event-sub-category/list.vue'),
        meta: {
          title: 'Event Sub Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Sub Category List',
              disabled: false,
              to: '/admin/event-sub-category',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'event-sub-category/new',
        name: 'admin.event-sub-category.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/event-sub-category/edit.vue'),
        meta: {
          title: 'Event Sub Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Sub Category List',
              disabled: false,
              to: '/admin/event-sub-category',
              exact: true,
            },
            {
              text: 'Add Event Sub Category',
              disabled: false,
              to: '/admin/event-sub-category/new',
            },
          ],
        },
      },
      {
        path: 'event-sub-category/edit/:id?',
        name: 'admin.event-sub-category.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/event-sub-category/edit.vue'),
        meta: {
          title: 'Event Sub Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event Sub Category List',
              disabled: false,
              to: '/admin/event-sub-category',
              exact: true,
            },
            {
              text: 'Edit Event Sub Category',
              disabled: false,
              to: '/admin/event-sub-category/edit',
            },
          ],
        },
      },
      {
        path: 'suppliers',
        name: 'admin.suppliers',
        component: () => import(/* webpackChunkName: "admin.suppliers" */ './views/suppliers/list.vue'),
        meta: {
          title: 'Suppliers',
          icon: 'mdi-cart-outline',
          breadcrumbs: [
            {
              text: 'Suppliers List',
              disabled: false,
              to: '/admin/suppliers',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'suppliers/new',
        name: 'admin.suppliers.add',
        component: () => import(/* webpackChunkName: "admin.suppliers" */ './views/suppliers/edit.vue'),
        meta: {
          title: 'Suppliers',
          icon: 'mdi-cart-outline',
          breadcrumbs: [
            {
              text: 'Suppliers List',
              disabled: false,
              to: '/admin/suppliers',
              exact: true,
            },
            {
              text: 'Add Supplier',
              disabled: false,
              to: '/admin/suppliers/new',
            },
          ],
        },
      },
      {
        path: 'suppliers/edit/:id?',
        name: 'admin.suppliers.edit',
        component: () => import(/* webpackChunkName: "admin.suppliers" */ './views/suppliers/edit.vue'),
        meta: {
          title: 'Suppliers',
          icon: 'mdi-cart-outline',
          breadcrumbs: [
            {
              text: 'Suppliers List',
              disabled: false,
              to: '/admin/suppliers',
              exact: true,
            },
            {
              text: 'Edit Supplier',
              disabled: false,
              to: '/admin/suppliers/edit',
            },
          ],
        },
      },
      {
        path: 'add-ons',
        name: 'admin.add-ons',
        component: () => import(/* webpackChunkName: "admin.add-ons" */ '@promoter/views/add-ons/list.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/admin/add-ons',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'add-ons/new',
        name: 'admin.add-ons.add',
        component: () => import(/* webpackChunkName: "promoter.add-ons" */ '@promoter/views/add-ons/edit/index.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/admin/add-ons',
              exact: true,
            },
            {
              text: 'Add Add Ons',
              disabled: false,
              to: '/admin/add-ons/new',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'add-ons/edit/:id?',
        name: 'admin.add-ons.edit',
        component: () => import(/* webpackChunkName: "admin.add-ons" */ '@promoter/views/add-ons/edit/index.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/admin/add-ons',
              exact: true,
            },
            {
              text: 'Edit Add Ons',
              disabled: false,
              to: '/admin/add-ons/edit',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'promoters',
        name: 'admin.promoters',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/promoters/list.vue'),
        meta: {
          title: 'Promoters',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Promoters List',
              disabled: false,
              to: '/admin/promoters',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'promoters/new',
        name: 'admin.promoters.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/promoters/edit.vue'),
        meta: {
          title: 'Promoters',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Promoters List',
              disabled: false,
              to: '/admin/promoters',
              exact: true,
            },
            {
              text: 'Add Promoter',
              disabled: false,
              to: '/admin/promoters/new',
            },
          ],
        },
      },
      {
        path: 'promoters/edit/:id?',
        name: 'admin.promoters.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/promoters/edit.vue'),
        meta: {
          title: 'Promoters',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Promoters List',
              disabled: false,
              to: '/admin/promoters',
              exact: true,
            },
            {
              text: 'Edit Promoter',
              disabled: false,
              to: '/admin/promoters/edit',
            },
          ],
        },
      },
      {
        path: 'reports',
        name: 'admin.reports',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/reports/list.vue'),
        meta: {
          title: 'Reports',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Reports List',
              disabled: false,
              to: '/admin/reports',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'seating-plan',
        name: 'admin.seating-plan',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/seating-plan/list.vue'),
        meta: {
          title: 'Seating Plan',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Seating Plan List',
              disabled: false,
              to: '/admin/seating-plan',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'seating-plan/new',
        name: 'admin.seating-plan.add',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/seating-plan/edit.vue'),
        meta: {
          title: 'Seating Plan',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Seating Plan List',
              disabled: false,
              to: '/admin/seating-plan',
              exact: true,
            },
            {
              text: 'Add Seating Plan',
              disabled: false,
              to: '/admin/seating-plan/new',
            },
          ],
        },
      },
      {
        path: 'seating-plan/edit/:id?',
        name: 'admin.seating-plan.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/seating-plan/edit.vue'),
        meta: {
          title: 'Seating Plan',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Seating Plan List',
              disabled: false,
              to: '/admin/seating-plan',
              exact: true,
            },
            {
              text: 'Edit Seating Plan',
              disabled: false,
              to: '/admin/seating-plan/edit',
            },
          ],
        },
      },
      {
        path: 'seats-io-designer',
        name: 'admin.seats-io-designer',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/seats-io-designer/list.vue'),
        meta: {
          title: 'SeatsIO Designer',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'SeatsIO Designer List',
              disabled: false,
              to: '/admin/seats-io-designer',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'seats-io-designer/new',
        name: 'admin.seats-io-designer.add',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/seats-io-designer/edit.vue'),
        meta: {
          title: 'SeatsIO Designer',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'SeatsIO Designer List',
              disabled: false,
              to: '/admin/seats-io-designer',
              exact: true,
            },
            {
              text: 'Add seatIO',
              disabled: false,
              to: '/admin/seats-io-designer/new',
            },
          ],
        },
      },
      {
        path: 'seats-io-designer/edit/:id?',
        name: 'admin.seats-io-designer.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/seats-io-designer/edit.vue'),
        meta: {
          title: 'SeatsIO Designer',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'SeatsIO Designer List',
              disabled: false,
              to: '/admin/seats-io-designer',
              exact: true,
            },
            {
              text: 'Edit seatIO',
              disabled: false,
              to: '/admin/seats-io-designer/edit',
            },
          ],
        },
      },
      {
        path: 'third-party',
        name: 'admin.third-party',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/third-party/list.vue'),
        meta: {
          title: 'Third Party',
          icon: 'mdi-account-multiple',
          breadcrumbs: [
            {
              text: 'Third Party List',
              disabled: false,
              to: '/admin/third-party',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'third-party/edit/:id?',
        name: 'admin.third-party.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/third-party/edit.vue'),
        meta: {
          title: 'Third Party',
          icon: 'mdi-account-multiple',
          breadcrumbs: [
            {
              text: 'Third Party List',
              disabled: false,
              to: '/admin/third-party',
              exact: true,
            },
            {
              text: 'Add third party',
              disabled: false,
              to: '/admin/third-party/edit',
            },
          ],
        },
      },
      {
        path: 'third-party/event/:id?',
        name: 'admin.third-party.event',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/third-party/event.vue'),
        meta: {
          title: 'Third Party',
          icon: 'mdi-account-multiple',
          breadcrumbs: [
            {
              text: 'Third Party List',
              disabled: false,
              to: '/admin/third-party',
              exact: true,
            },
            {
              text: 'Events',
              disabled: false,
              to: '/admin/third-party/event',
            },
          ],
        },
      },
      {
        path: 'third-party/new',
        name: 'admin.third-party.add',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/third-party/edit.vue'),
        meta: {
          title: 'Third Party',
          icon: 'mdi-account-multiple',
          breadcrumbs: [
            {
              text: 'Third Party List',
              disabled: false,
              to: '/admin/third-party',
              exact: true,
            },
            {
              text: 'Add third party',
              disabled: false,
              to: '/admin/third-party/new',
            },
          ],
        },
      },
      {
        path: 'ticket-category',
        name: 'admin.ticket-category',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/ticket-category/list.vue'),
        meta: {
          title: 'Ticket Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Ticket Category List',
              disabled: false,
              to: '/admin/ticket-category',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'ticket-category/new',
        name: 'admin.ticket-category.add',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/ticket-category/edit.vue'),
        meta: {
          title: 'Ticket Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Ticket Category List',
              disabled: false,
              to: '/admin/ticket-category',
              exact: true,
            },
            {
              text: 'Add Ticket Category',
              disabled: false,
              to: '/admin/ticket-category/new',
            },
          ],
        },
      },
      {
        path: 'ticket-category/edit/:id?',
        name: 'admin.ticket-category.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/ticket-category/edit.vue'),
        meta: {
          title: 'Ticket Category',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Ticket Category List',
              disabled: false,
              to: '/admin/ticket-category',
              exact: true,
            },
            {
              text: 'Edit Ticket Category',
              disabled: false,
              to: '/admin/ticket-category/edit',
            },
          ],
        },
      },
      {
        path: 'venues',
        name: 'admin.venues',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/venues/list.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Venues List',
              disabled: false,
              to: '/admin/venues',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'venues/new',
        name: 'admin.venues.add',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/venues/edit.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Venues List',
              disabled: false,
              to: '/admin/venues',
              exact: true,
            },
            {
              text: 'Add Venue',
              disabled: false,
              to: '/admin/venues/new',
            },
          ],
        },
      },
      {
        path: 'venues/view/:id',
        name: 'admin.venues.view',
        component: () => import('./views/venues/view.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/admin/venues',
              exact: true,
            },
            {
              text: 'Venue Details',
              disabled: false,
              to: '/admin/venues/view',
            },
          ],
        },
      },
      {
        path: 'venues/edit/:id?',
        name: 'admin.venues.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/venues/edit.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Venues List',
              disabled: false,
              to: '/admin/venues',
              exact: true,
            },
            {
              text: 'Edit Venue',
              disabled: false,
              to: '/admin/venues/edit',
            },
          ],
        },
      },
      {
        path: 'sales-reports',
        name: 'admin.sales-reports',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/sales-reports/list.vue'),
        meta: {
          title: 'Customer Order History',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Customer Order History',
              disabled: false,
              to: '/admin/sales-reports',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'custom-widget',
        name: 'admin.custom-widget',
        component: () => import(/* webpackChunkName: "admin.users" */ './views/custom-widget/list.vue'),
        meta: {
          title: 'Custom Widget',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Custom Widget List',
              disabled: false,
              to: '/admin/custom-widget',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'custom-widget/new',
        name: 'admin.custom-widget.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/custom-widget/edit.vue'),
        meta: {
          title: 'Custom Widget',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Custom Widget List',
              disabled: false,
              to: '/admin/custom-widget',
              exact: true,
            },
            {
              text: 'Add Custom Widget',
              disabled: false,
              to: '/admin/custom-widget/new',
            },
          ],
        },
      },
      {
        path: 'custom-widget/edit/:id?',
        name: 'admin.custom-widget.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ './views/custom-widget/edit.vue'),
        meta: {
          title: 'Custom Widget',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Custom Widget List',
              disabled: false,
              to: '/admin/custom-widget',
              exact: true,
            },
            {
              text: 'Edit Custom Widget',
              disabled: false,
              to: '/admin/custom-widget/edit',
            },
          ],
        },
      },
      {
        path: 'survey',
        name: 'admin.survey',
        component: () => import(/* webpackChunkName: "admin.users" */ '@promoter/views/survey/list.vue'),
        meta: {
          title: 'Survey',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/admin/survey',
              exact: true,
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'survey/new',
        name: 'admin.survey.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ '@promoter/views/survey/edit.vue'),
        meta: {
          title: 'Survey',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/admin/survey',
              exact: true,
            },
            {
              text: 'Add Survey',
              disabled: false,
              to: '/admin/survey/new',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
      {
        path: 'survey/edit/:id?',
        name: 'admin.survey.edit',
        component: () => import(/* webpackChunkName: "admin.accounts" */ '@promoter/views/survey/edit.vue'),
        meta: {
          title: 'Survey',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/admin/survey',
              exact: true,
            },
            {
              text: 'Edit Survey',
              disabled: false,
              to: '/admin/survey/edit',
            },
          ],
        },
        props: {
          fromAdmin: true,
        },
      },
    ],
    meta: {
      auth: 'admin',
    },
  },
];
