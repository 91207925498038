import Api from './api.service';
export default {
  addToCart(data) {
    return Api().post('/ticket-booking/add-cart', data);
  },
  removeFromCart(data) {
    return Api().put('/ticket-booking/remove-cart', data);
  },
  updateLogsStatus(data) {
    return Api().put('/ticket-booking/update-log-status', data);
  },
  purchaseTickets(data) {
    return Api({ hideErrorMessage: true }).post('/ticket-booking/tickets', data);
  },
  confirmTickets(data) {
    return Api({ hideErrorMessage: true }).put('/ticket-booking/tickets/confirm', data);
  },
  validatePromocode(data) {
    return Api().post('/ticket-booking/validate-promocode', data)
  }
};
