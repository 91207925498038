import Api from './api.service';
export default {
  get(params) {
    return Api().get('/custom-widgets', { params });
  },
  getById(id) {
    return Api().get(`/custom-widgets/${id}`);
  },
  update(data) {
    return Api().put(`/custom-widgets/${data._id}`, data);
  },
  save(data) {
    return Api().post('/custom-widgets', data);
  },
  createGlobalEvent(event) {
    return Api().post('/custom-widgets/global/event', { event });
  },
  checkDuplicate(text, params) {
    return Api().get(`/custom-widgets/duplicate/${text}`, { params });
  },
  delete(id) {
    return Api().delete(`/custom-widgets/${id}`);
  },
  search(name) {
    return Api().get(`/custom-widgets/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/custom-widgets/export', {
      responseType: 'blob',
      params,
    });
  },
  getEventInfo(code, eventId) {
    return Api().get(`/custom-widgets/event/${code}/${eventId}`);
  },
};
