import Api from './api.service';
export default {
  get(params) {
    return Api().get('/survey', { params });
  },
  save(data) {
    return Api().post('/survey', data);
  },
  getById(id) {
    return Api().get('/survey/'+id);
  },
  delete(id) {
    return Api().delete('/survey/'+id);
  },
  update(data, id) {
    return Api().put('/survey/'+id, data);
  },
  getByEvent(id) {
    return Api().get('/survey/event/'+id);
  },
  exportExcel(params) {
    return Api().get('/survey/export', {
      responseType: 'blob',
      params,
    });
  },
  eventExportExcel(params, id) {
    return Api().get(`/survey/event/export/${id}`, {
      responseType: 'blob',
      params,
    });
  },
};
