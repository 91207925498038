export default [
  {
    path: '/visitor/login',
    name: 'visitor.login',
    component: () => import(/* webpackChunkName: "login" */ './views/auth/login.vue'),
  },
  {
    path: '/visitor/register',
    name: 'visitor.register',
    component: () => import(/* webpackChunkName: "visitor.register" */ './views/auth/login.vue'),
    props: {
      activeTab: 1,
    },
  },
  {
    path: '/',
    name: 'visitor',
    component: () => import(/* webpackChunkName: "visitor" */ './layout.vue'),
    children: [
      {
        path: '/',
        name: 'visitor.dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ './views/home/index.vue'),
      },
      {
        path: '/privacy',
        name: 'visitor.privacy',
        component: () => import(/* webpackChunkName: "privacy" */ './views/privacy.vue'),
      },
      {
        path: '/about-us',
        name: 'visitor.about-us',
        component: () => import(/* webpackChunkName: "about" */ './views/about-us.vue'),
      },
      {
        path: '/stripe/refresh',
        name: 'visitor.refresh',
        component: () => import(/* webpackChunkName: "refresh" */ './views/refresh.vue'),
      },
      {
        path: '/stripe/return',
        name: 'visitor.return',
        component: () => import(/* webpackChunkName: "return" */ './views/return.vue'),
      },
      {
        path: '/terms',
        name: 'visitor.terms',
        component: () => import(/* webpackChunkName: "terms" */ './views/terms.vue'),
      },
      {
        path: '/account',
        name: 'visitor.account.view',
        component: () => import(/* webpackChunkName: "about" */ './views/account/view/index.vue'),
        meta: {
          auth: 'user',
        },
      },
      {
        path: '/account/edit',
        name: 'visitor.account.edit',
        component: () => import(/* webpackChunkName: "about" */ './views/account/edit/index.vue'),
        meta: {
          auth: 'user',
        },
      },
      {
        path: '/event-search',
        name: 'visitor.event-search',
        component: () => import(/* webpackChunkName: "event-search" */ './views/event/event-search.vue'),
      },
      {
        path: '/event-api',
        name: 'visitor.event-api',
        component: () => import(/* webpackChunkName: "event-api" */ './views/event/event-api.vue'),
      },
      {
        path: '/event-widget/:id/:eventId',
        name: 'visitor.event-widget',
        component: () => import(/* webpackChunkName: "event-widget" */ './views/event/event-widget.vue'),
      },
      {
        path: '/:id',
        name: 'visitor.event',
        component: () => import(/* webpackChunkName: "about" */ './views/event/index.vue'),
      },
      {
        path: '/event-venue/:id',
        name: 'visitor.event-venue',
        component: () => import(/* webpackChunkName: "event-venue" */ './views/event/event-venue.vue'),
      },
      {
        path: '/event-list/:id',
        name: 'visitor.event-list',
        component: () => import(/* webpackChunkName: "event-list" */ './views/event/event-lists.vue'),
      },
      {
        path: '/event-promoter/:id',
        name: 'visitor.event-promoter',
        component: () => import(/* webpackChunkName: "event-promoter" */ './views/event/event-promoter.vue'),
      },
    ],
    meta: {
      auth: false,
    },
  },
];
