<template>
  <v-card flat :outlined="$attrs.outlined">
    <v-tabs v-model="tab" grow>
      <v-tab>Login</v-tab>
      <v-tab>Sign up</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row justify="center">
          <v-col cols="12" sm="10">
            <v-form ref="loginForm" v-model="form.login" @keyup.native.enter="login()" lazy-validation>
              <v-card-title>Log in to book tickets</v-card-title>

              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  type="email"
                  name="name"
                  label="Email"
                  v-model="email"
                  :rules="[
                    v => !!v || 'Email is required',
                    v => (v && !isFormSubmited) || /.+@.+\..+/.test(v) || 'Email must be valid',
                  ]"
                ></v-text-field>

                <v-text-field
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  outlined
                  dense
                  name="name"
                  label="Password"
                  v-model="password"
                  :rules="[v => !!v || 'Password is required']"
                ></v-text-field>

                <v-btn block color="primary" :disabled="!email || !password" :loading="loading.login" @click="login()"
                  >Login</v-btn
                >

                <a class="d-block py-4 text-center text-decoration-underline" @click="forgotpassword"
                  >I forgot my password</a
                >
                <a class="d-block py-4 text-center text-decoration-underline" @click="loginAsPromoter"
                  >Login as a Promoter</a
                >
                <div class="text-subtitle-2 d-block text-center" v-if="isUserNeedVerification">
                  Want to verify your account?
                  <a @click="verifyUser" class="text-decoration-underline">
                    Click Here
                  </a>
                </div>
              </v-card-text>

              <social-login
                v-if="firebaseLoaded"
                :loading="loading"
                @facebook="signInWithFacebook()"
                @google="signInWithGoogle()"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item class="px-0 px-sm-4">
        <v-form ref="registerForm" v-model="form.register" lazy-validation>
          <v-card-title>Welcome to Ticket Text </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  outlined
                  dense
                  name="name"
                  label="First name"
                  v-model="user.firstName"
                  :rules="[
                    v => !!v || 'First name is required',
                    v => (v && v.length < 20) || 'First name must less than 20 character',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  outlined
                  dense
                  name="name"
                  label="Last name"
                  v-model="user.lastName"
                  :rules="[
                    v => !!v || 'Last name is required',
                    v => (v && v.length < 20) || 'Last name must less than 20 character',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  type="email"
                  name="name"
                  label="Email"
                  @blur="checkEmail()"
                  v-model="user.email"
                  :rules="[
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                    v => isEmailDublicate || 'Email must be unique',
                  ]"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  outlined
                  dense
                  name="name"
                  label="Company name"
                  v-model="user.companyName"
                  :rules="[
                    v => !!v || 'Company name is required',
                    v => (v && v.length < 50) || 'Last name must less than 50 character',
                  ]"
                ></v-text-field>
              </v-col> -->
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  outlined
                  dense
                  name="name"
                  label="Create password"
                  v-model="user.password"
                  :rules="[v => !!v || 'Password is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"
                  outlined
                  dense
                  name="name"
                  label="Confirm password"
                  v-model="confirmPassword"
                  :rules="[
                    v => !!v || 'Confirm password is required',
                    v => v == user.password || 'Confirm password does not match',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox class="mt-0" v-model="agree" :rules="[v => !!v || '']">
                  <template slot="label">
                    <div class="text-subtitle-2">
                      By creating an account, I agree to the
                      <a @click.stop target="_blank" href="https://web-1.staging.tickettext.co.uk/terms">
                        Terms and Conditions
                      </a>
                      and
                      <a @click.stop target="_blank" href="https://web-1.staging.tickettext.co.uk/privacy">
                        Privacy policy.
                      </a>
                    </div>
                  </template>
                </v-checkbox>

                <v-btn
                  block
                  color="primary"
                  :disabled="!agree || !confirmPassword || !form.register"
                  :loading="loading.register"
                  @click="register()"
                  >Create account</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <social-login
            signup
            v-if="firebaseLoaded"
            :loading="loading"
            @facebook="signInWithFacebook()"
            @google="signInWithGoogle()"
          />
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { AuthService, FirebaseService, PaymentService, EventService } from '@services';
import SocialLogin from './social-login';
 
export default {
  props: ['activeTab'],

  components: {
    SocialLogin,
  },

  data() {
    return {
      tab: this.activeTab,
      isFormSubmited: false,
      isEmailDublicate: true,
      show1: false,
      show2: false,
      show3: false,
      isUserNeedVerification: false,
      form: {
        login: false,
        register: false,
      },
      loading: {
        login: false,
        register: false,
        google: false,
        facebook: false,
      },
      user: {
        companyName: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        role: 'user',
      },
      email: '',
      password: '',
      confirmPassword: null,
      agree: false,
      firebaseLoaded: false,
    };
  },
  async mounted() {
    if (!window.firebase) {
      await this.$loadScript('https://www.gstatic.com/firebasejs/7.21.0/firebase-app.js');
      await this.$loadScript('https://www.gstatic.com/firebasejs/7.21.0/firebase-auth.js');

      FirebaseService.initializeApp();
    }
    this.firebaseLoaded = true;
  },
  methods: {
    async login() {
      this.loading.login = true;
      this.isFormSubmited = true;
      if (this.$refs.loginForm.validate()) {
        const response = await AuthService.login({
          email: this.email,
          password: this.password,
        });
        this.isUserNeedVerification = response.isUserNeedVerification;
        this.isFormSubmited = false;
        if (response && !this.isUserNeedVerification) {
          // if(response.user.role === "promoter") {
          //   //PaymentService.getAccountInfo(response.user.stripe.accountId);
          //   const showKYC = true;
          //   console.log(response.user)
          //   localStorage.setItem('account_id', response.user.stripe.accountId);
          //   EventService.$emit('user-kyc', 'We have updated the website. KYC is required to <br />make payouts.', 'user-kyc');
          // }
          this.$login(response);
          this.$emit('close');
        }
      }
      this.loading.login = false;
    },
    async register() {
      this.loading.register = true;

      if (this.$refs.registerForm.validate()) {
        const response = await AuthService.promoterRegister(this.user);
        if (response) {
          this.$emit('close');
          this.$root.$emit('promoter-verify', this.user.email);
          this.$refs.registerForm.reset();
        }
      }
      this.loading.register = false;
    },
    async verifyUser() {
      if (this.$refs.loginForm.validate()) {
        const response = await AuthService.resend({ email: this.email });
        if (response) {
          this.$root.$emit('promoter-verify', this.email);
          this.$emit('close');
        }
      }
    },
    forgotpassword() {
      this.$root.$emit('forgot-password', this.email);
      this.$emit('close');
    },
    loginAsPromoter() {
      this.$next({
          name: 'promoter.login',
        });
    },  
    async checkEmail() {
      const response = await AuthService.checkEmail({ email: this.user.email });
      if (response && response.isEmailDublicate) {
        this.isEmailDublicate = false;
      } else {
        this.isEmailDublicate = true;
      }
    },
    async signInCallback(result) {
      const profile = result.additionalUserInfo.profile;
      const provider = result.additionalUserInfo.providerId.replace('.com', '');

      if (profile.email) {
        this.user.email = profile.email;
        this.user.firstName = profile.given_name || profile.first_name;
        this.user.lastName = profile.family_name || profile.last_name;
        // this.user.image = profile.picture;
        this.user.provider = provider;
        this.user.strategy = {
          [provider]: profile.id,
        };

        const response = await AuthService.socialLogin(this.user);

        if (response) {
          this.$login(response);
          this.$emit('close');
          return true;
        }
      } else {
        this.$error('Could not find email address from provider, Please try with email login');
      }
    },
    async signInWithGoogle() {
      this.loading.google = true;

      const response = await FirebaseService.signInWithGoogle();
      if (response) {
        await this.signInCallback(response);
      }

      this.loading.google = false;
    },
    async signInWithFacebook() {
      this.loading.facebook = true;

      const response = await FirebaseService.signInWithFacebook();
      if (response) {
        await this.signInCallback(response);
      }

      this.loading.facebook = false;
    },
  },
};
</script>
