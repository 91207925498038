import Vue from 'vue';
// import moment from 'moment';
import moment from 'moment-timezone';

// Europe/London

const timezone = 'Europe/London'

Vue.filter('date', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('DD/MM/YYYY');
});

Vue.filter('time', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('hh:mm A');
});

Vue.filter('dateTime', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('DD/MM/YYYY hh:mm A');
});

Vue.filter('dateTimeshort', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('DD/MM/YY hh:mm A');
});

Vue.filter('month', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('MMM');
});

Vue.filter('monthyear', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('MMM YYYY');
});

Vue.filter('day', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('D');
});

Vue.filter('dateWithDay', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('ddd, DD MMM YYYY, hh:mm A');
});

Vue.filter('fullday', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('ddd, MMM DD, YYYY');
});

Vue.filter('fullDateAndTime', function(value) {
  if (!value) return 'N/A';
  return moment(value).tz(timezone).format('ddd, MMM DD, YYYY : hh:mm A');
});
