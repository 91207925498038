<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="onCancel()">
    <v-card class="pa-sm-4">
      <v-card-title class="justify-center">
        <v-icon color="error" x-large>mdi-help-circle-outline</v-icon>
      </v-card-title>

      <v-card-text>
        <v-card-title v-if="title" class="my-2 justify-center" v-text="title"></v-card-title>
        <v-card-title style="padding: 0px 16px" v-if="promoterMessage" class="justify-center" v-text="promoterMessage"></v-card-title>
        <v-card-title style="padding: 0px 16px" v-if="ticketTextMessage" class="justify-center" v-text="ticketTextMessage"></v-card-title>
        <v-card-title style="padding: 0px 16px" v-if="confirmMessage" class="justify-center" v-text="confirmMessage"></v-card-title>
        <v-card-subtitle class="text-center" v-if="subtitle" v-text="subtitle"></v-card-subtitle>
      </v-card-text>

      <v-card-actions>
        <div class="mx-auto">
          <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>

          <v-btn class="mx-1" width="100" color="primary" @click="onClick()">Yes</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: null,
      promoterMessage: null,
      ticketTextMessage: null,
      subtitle: null,
      confirmMessage: null,
    };
  },
  mounted() {
    this.$root.$on('confirm', options => {
      if (options.skip) {
        if (typeof options.resolve === 'function') {
          options.resolve();
        }
      } else if (!options.disabled) {
        this.dialog = true;
        this.title = options.title;
        this.confirmMessage = options.confirmMessage;
        this.promoterMessage = options.promoterMessage;
        this.ticketTextMessage = options.ticketTextMessage;
        this.subtitle = options.subtitle;
        this.resolve = options.resolve;
        this.reject = options.reject;
      }
    });
  },
  methods: {
    onClick() {
      this.dialog = false;
      if (typeof this.resolve === 'function') {
        this.resolve();
      }
    },
    onCancel() {
      this.dialog = false;
      if (typeof this.reject === 'function') {
        this.reject();
      }
    },
  },
};
</script>
