<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500px" height="100vh">
    <v-card min-height="80vh">
      <Base @close="dialog = false" :activeTab="activeTab"/>
      <tt-icon class="hidden-xs-only login-logo position-absolute justify-center ma-auto mb-4" width="50" icon="logo" />
    </v-card>
  </v-dialog>
</template>

<script>
import Base from './base';

export default {
  components: {
    Base,
  },
  data() {
    return {
      dialog: false,
      activeTab: 0
    };
  },
  mounted() {
    this.$root.$on('promoter-login', (data) => {
      if (data && data === 'homePage') {
        this.activeTab = 1;
      } else {
        this.activeTab = 0;
      }
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.bg-image {
  background: url('../../../../assets/images/promoter-login.svg') no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.login-logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
