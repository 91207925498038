import Api from './api.service';
export default {
  getUpcomingEvents(params) {
    return Api().get('/events/upcoming-events', { params });
  },
  getEventGenres() {
    return Api().get('/genres/event-genre');
  },
  getThirdPartyEvents(params) {
    return Api().post('/third-party-users/event-list', params);
  }
};
