import Vue from 'vue';

Vue.filter('capitalize', function(value) {
  if (!value) return 'N/A';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('currency', function(value) {
  if (!value && value !== 0) return '£0.00';
  return `£${Number(value / 100).toFixed(2)}`;
});
