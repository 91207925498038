<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
          <v-card-title>Enter number of ticket for select </v-card-title>

          <v-card-text>
            <v-text-field
              outlined
              :maxlength="2"
              name="ticket"
              label="No of Tickets"
              v-on:keypress="isNumber($event)"
              v-model="number"
              :rules="[v => !!v || 'No of Tickets is required', checkLimit]"
            ></v-text-field>
          </v-card-text>
          <v-spacer />
          <v-card-actions>
            <div class="mx-auto">
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>

              <v-btn class="mx-1" width="150" color="primary" @click="onClick()">Select Seat</v-btn>
            </div>
          </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      number: null,
      form: false,
    };
  },
  mounted() {
    this.$root.$on('ticket', options => {
      this.number = null;
      if (options.skip) {
        if (typeof options.resolve === 'function') {
          options.resolve();
        }
      } else if (!options.disabled) {
        this.dialog = true;
        this.limit = options.limit;

        this.resolve = options.resolve;
        this.reject = options.reject;
      }
    });
  },
  computed: {
    checkLimit() {
      return !(this.limit && this.number > this.limit) || `You can not select seats more than ${this.limit}.`;
    },
    quantityRule() {
      return (
        !(
          this.data.maximumQuantityCanBePurchased &&
          this.data.minimumQuantityCanBePurchased &&
          Number(this.data.minimumQuantityCanBePurchased) > Number(this.data.maximumQuantityCanBePurchased)
        ) || 'Maximum Quantity must be greater than Minimum Quantity'
      );
    },
    discountRequiredRule() {
      return !(!this.data.discountedPrice && !this.data.discountedPercentage) || 'Discount Amount is required';
    },
  },
  methods: {
    onClick() {
      if (this.$refs.forgotPasswordForm.validate()) {
        this.dialog = false;
        this.number = this.number.replace(/^0+/, ''); // removing leading zeros
        if (typeof this.resolve === 'function') {
          this.resolve(this.number);
        }
      }
    },
    onCancel() {
      this.dialog = false;
      if (typeof this.reject === 'function') {
        this.reject();
      }
    },
  },
};
</script>
