import Api from './api.service';
export default {
  get(id, params) {
    return Api().get(`/events/promoter/${id}`, { params });
  },
  getAll(params) {
    console.log("🚀 ~ file: promoter-event.service.js ~ line 7 ~ getAll ~ params", params)
    return Api().get(`/events/all/`, { params });
  },
  getFeaturedEvents() {
    return Api().get(`/events/event-all?isFeatured=true`);
  },
  getById(id) {
    return Api().get(`/events/${id}?forAdmin=1`);
  },
  update(data) {
    return Api().put(`/events/${data._id}`, data);
  },
  save(data) {
    if (data._id) {
      return Api().put(`/events/${data._id}`, data);
    } else {
      return Api().post('/events', data);
    }
  },
  delete(id) {
    return Api().delete(`/events/${id}`);
  },
  duplicate(id) {
    return Api().post(`/events/duplicate/${id}`);
  },
  getTicketCategories() {
    return Api().get('/ticket-categories/search/all_ticket_category');
  },
  getPerformersSearch() {
    return Api().get(`/performers?limit=max`);
  },
  getVenuesSearch(params) {
    return Api().get(`/venues`, { params });
  },
  addVenue(data) {
    return Api().post(`/venues`, data);
  },
  getEventSeries(params) {
    return Api().get(`/event-series/`, { params });
  },
  getEventTypes() {
    return Api().get(`/event-types/`);
  },
  getGenreSearch(params) {
    return Api().get(`/genres/`, { params });
  },
  getSettingPlan(params) {
    return Api().get(`/seating-plans/`, { params });
  },
  addTicket(id, params) {
    return Api().post(`/events/${id}/ticket-category`, params);
  },
  getSameEvents(data) {
    return Api().post(`/events/same-events`, data);
  },
  getSameEventsWithoutLogin(data) {
    return Api().post(`/events/same-events-without-login`, data);
  },
  editTicket(eventid, ticketid, params) {
    return Api().put(`/events/${eventid}/ticket-category/${ticketid}`, params);
  },
  resendTicket(ids) {
    return Api().post(`/ticket-assignments/resendTickets`, ids);
  },
  sendApprovedMail(data) {
    return Api().put(`/events/send-approved-mail`, data);
  },
  cancelEvent(id) {
    return Api().post(`/events/cancel-event/${id}`);
  },
  saveTicket(eventId, params) {
    if (params._id) {
      return Api().put(`/events/${eventId}/ticket-category/${params._id}`, params);
    } else {
      return Api().post(`/events/${eventId}/ticket-category`, params);
    }
  },
  saveTickets(eventId, params) {
    return Api().post(`/events/${eventId}/ticket-category/multiple`, params);
  },
  deleteTicketCategory(eventId, id) {
    return Api().delete(`/events/${eventId}/ticket-category/${id}`);
  },
  getDashboardEvents() {
    return Api().get(`/organisers/dashboard/events`);
  },
  getDashboardCharts(params) {
    return Api().get(`/organisers/dashboard/chart`, { params });
  },
  getAdminDashboardCharts(params) {
    return Api().get(`/events/admin/dashboard/chart`, { params });
  },
  getAdminDashboardSalesCharts(params) {
    return Api().get(`/events/admin/dashboard/sales-chart`, { params });
  },
  getPayoutList(params) {
    return Api().get(`/events/admin/dashboard/payout-list`, { params });
  },
  getDashboardData() {
    return Api().get(`/events/admin/dashboard/data`);
  },
  moveTickets(data) {
    return Api().post('/tickets/transfer-tickets', data);
  },
  changeEmail(data) {
    return Api().put(`/ticket-assignments/update-email/${data._id}`, data)
  },
  getEventDashboard(id) {
    return Api().get(`/events/${id}/dashboard`);
  },
  exportExcel(params) {
    return Api().get('/events/export', {
      responseType: 'blob',
      params,
    });
  },
  PromoterEventService(params) {
    return Api().get('/tickets/printTicketList', {
      responseType: 'blob',
      params,
    });
  },
  getTickets(params) {
    return Api().get('/tickets/ticketList', { params });
  },
  getCalloverList(params) {
    return Api().get('/tickets/callover-list', { params });
  },
  exportCalloverList(params) {
    return Api().get('/tickets/callover-list/export', {
      responseType: 'blob',
      params,
    });
  },
  sendEmailTickets(params) {
    return Api().get('/tickets/send-ticket-list', { params });
  },
  sendEventReminder(data) {
    return Api().post('/events/event-reminder', data);
  },
  refundTicket(id, data) {
    return Api().put(`/payments/stripe/refund/${id}`,data);
  },
  getReservationStatus(id) {
    return Api().get(`events/${id}/reservation-status`);
  },
  createReservation(categoryId, data) {
    return Api().post(`tickets/reserve/${categoryId}`, data);
  },
  updateBucketTicketByEventTicketCategory(categoryId, data) {
    return Api().put(`events/update-bucket-ticket/${categoryId}`, data);
  },
  releaseBucketTicketByEventTicketCategory(data) {
    return Api().put(`events/release-bucket-ticket`, data);
  },
  payment(data) {
    return Api().post('payments/stripe', data);
  },
  seatsIoConfirm(data) {
    return Api({ hideErrorMessage: true }).post('/seatsios/confirmed', data);
  },
  seatsIoRelease(data) {
    return Api().post('/seatsios/release', data);
  },
  changeHoldTokenTime(data) {
    return Api().post('/seatsios/change-time-hold-token', data);
  },
  getSeatsIOTickets(holdToken) {
    return Api().get(`/seatsios/get-tickets/${holdToken}`);
  },
  getEventByLongId(id) {
    return Api().get(`/ticket-assignments/${id}`);
  },
  resendTicketEmail(id) {
    return Api().put(`/ticket-assignments/${id}/resend-email`, { longId: id });
  },
  subscriptions(data) {
    return Api().post(`/subscriptions`, data);
  },
  creaetPayout(id, data) {
    return Api().post(`/organisers/createPayout/${id}`, data);
  },
  getEventsByVenue(id) {
    return Api().get(`/events/event-venue/${id}`);
  },
  getEventsByCode(id) {
    return Api().get(`/custom-widgets/list-events/${id}`);
  },
  getEventsByPromoter(id) {
    return Api().get(`/events/event-promoter/${id}`);
  },
  likedEvents(data) {
    return Api().post(`/event-likeds`, data);
  },
  unLikedEvents(data) {
    return Api().post(`/event-likeds/un-liked`, data);
  },
  getGuestList(params) {
    return Api().get('/events/getGuestList', { params });
  },
  sendEmailNotification(data) {
    return  Api().post(`/ticket-assignments/sendEmailNotification`, data);
  },
  callOverListPDF(data) {
    return  Api().post(`/tickets/pdf-ticket-list`, data);
  },
  getSurveyReport(params, eventId) {
    return Api().get(`/tickets/survey/${eventId}`, { params });
  },
  exportSurveyReport(params, eventId) {
    return Api().get(`/tickets/survey/export/${eventId}`, {
      responseType: 'blob',
      params,
    });
  },
};
