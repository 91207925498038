<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card min-height="30vh">
      <v-card-text>
          <v-card-title>Select Event</v-card-title>
           <v-autocomplete
              v-model="event"
              :items="events"
              clearable
              :item-text="'title'"
              :filter="customFilter"
              :item-value="'_id'"
              label="Events"
              :rules="[v => !!v || 'Events is required']"
              dense
              outlined
            >
             <template v-slot:selection="data">
                <span>{{ data.item.title }} </span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.title }}  - {{ formateDate(data.item)}}</span>
              </template>
              
            </v-autocomplete>
          <v-spacer />
          <v-card-actions>
            <div class="mx-auto">
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>

              <v-btn class="mx-1" width="150" :loading="loading" color="primary" @click="onClick()">Submit</v-btn>
            </div>
          </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      dialog: false,
      event: null,
      form: false,
      loading: false,
      seats: [],
      overlay: false,
    };
  },
  mounted() {
    this.$root.$on('event-list', (seats) => {
      this.getData();
      this.seats = [];
      this.event = null;
      for (let index = 0; index < seats.length; index++) {
        const element = seats[index];
        this.seats.push(element._id)
      }
      this.dialog = true;
    });
  },
  methods: {
    formateDate(item) {
      return moment(item.startsAt).format('DD/MM/YYYY HH:mm A')
    },
    customFilter (item, queryText) {
      const textOne = `${item.title.toLowerCase()} - ${moment(item.startsAt).format('DD/MM/YYYY HH:mm A')}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getData() {
        this.overlay = true;
        this.events = await PromoterEventService.getAll({ status: 'active', isUpcomig: true});
        this.overlay = false;
    },
    async onClick() {
      if (this.event) {
        this.loading = true;
        const response = await PromoterEventService.moveTickets({
          fromEventId: this.$route.params.id,
          toEventId: this.event,
          ids: this.seats
        })
        if (response) {
          this.$next({ name: 'admin.events.callover-list', params: { id: this.event } });
          this.$root.$emit('move-ticket-success');
          this.dialog = false;
        }
        this.loading = false;
      }
    },
    onCancel() {
      this.dialog = false;
    },
  },
};
</script>
