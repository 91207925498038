import Api from './api.service';
export default {
  getSuppliers(params) {
    if (params.status === 'all') {
      params.status = null;
    }
    return Api().get('/suppliers', { params });
  },
  save(data) {
    return Api().post('/suppliers', data);
  },
  getById(id) {
    return Api().get('/suppliers/'+id);
  },
  delete(id) {
    return Api().delete('/suppliers/'+id);
  },
  update(data, id) {
    return Api().put('/suppliers/'+id, data);
  },
  exportExcel(params) {
    return Api().get('/suppliers/export', {
      responseType: 'blob',
      params,
    });
  },
  checkDuplicate(text, params) {
    return Api().get(`/suppliers/duplicate/${text}`, { params });
  }
};
