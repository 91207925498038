<template>
  <v-img contain v-bind="$attrs" :src="require(`@/assets/icons/${icon}.svg`)" />
</template>

<script>
export default {
  name: 'ttIcon',
  props: {
    icon: { type: String, required: true },
  },
};
</script>
