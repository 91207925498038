import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@store';

import adminRoutes from '@admin/routes';
import promoterRoutes from '@promoter/routes';
import visitorRoutes from '@visitor/routes';
import venueRoutes from '@venue/routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "home" */ '@views/welcome.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@views/login.vue'),
  },

  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "403" */ '@views/403.vue'),
  },

  ...adminRoutes,
  ...promoterRoutes,
  ...visitorRoutes,
  ...venueRoutes,

  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const user = store.state.auth.user;

  const meta = (to.matched || []).reduce((obj, item) => {
    return Object.assign(obj, item.meta);
  }, {});

  if (user) {
    if (meta.auth) {
      if (meta.auth === user.role) {
        next();
      } else if (to.name != '403') {
        next({ name: '403' });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (meta.auth) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

export default router;
