import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#D93D34',
        light: '#FFFFFF',
        dark: '#121212',
        lightOnly: '#FFFFFF',
        darkOnly: '#FFFFFF',
      },
      dark: {
        primary: '#D93D34',
        light: '#121212',
        dark: '#FFFFFF',
        lightOnly: '#121212',
        darkOnly: '#121212',
      },
    },
  },
});
