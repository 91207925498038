import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import modules from './modules';

Vue.use(Vuex);

let options = {};

if (process.env.VUE_APP_SECURE_LS == 'true') {
  const ls = new SecureLS({
    encodingType: process.env.VUE_APP_SECURE_LS_ENCODING_TYPE,
    isCompression: process.env.VUE_APP_SECURE_LS_COMPRESSION,
    encryptionSecret: process.env.VUE_APP_SECURE_LS_ENCRYPTION_SECRET,
  });

  options = {
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key),
    },
  };
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [pathify.plugin, createPersistedState(options)],
});
