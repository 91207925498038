<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <v-card class="pa-sm-4">
      <v-card-title class="justify-center">
        <v-icon color="success" v-show="iconName === null" x-large>mdi-check-circle-outline</v-icon>
        <v-icon color="error" v-show="iconName === 'error'" x-large>mdi-close-circle-outline</v-icon>
      </v-card-title>

      <v-card-text>
        <v-card-title class="my-2 justify-center" v-html="title"></v-card-title>
        <v-card-subtitle class="text-center" v-if="subtitle" v-text="subtitle"></v-card-subtitle>
        <div v-if="showBody" v-html="bodyContent">
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="!isRefresh" class="mx-auto" width="100" color="primary" @click="onClick()">Ok</v-btn>
        <v-btn v-if="isRefresh" class="mx-auto" width="100" color="primary" @click="onRefresh()">Refresh</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: null,
      subtitle: null,
      resolve: null,
      iconName: null,
      isRefresh: null,
      showBody: true,
      bodyContent: ''
    };
  },
  mounted() {
    this.$root.$on('alert', ({ title, subtitle, resolve, iconName = null, isRefresh = null, showBody = true, bodyContent = '' }) => {
      this.dialog = true;
      this.title = title;
      this.subtitle = subtitle;
      this.resolve = resolve;
      this.isRefresh = isRefresh;
      this.iconName = iconName;
      this.showBody = showBody;
      this.bodyContent = bodyContent;
    });
  },
  methods: {
    onClick() {
      this.dialog = false;
      if (typeof this.resolve === 'function') {
        this.resolve();
      }
    },
    onRefresh() {
      window.location.reload();
    }
  },
};
</script>
