import Api from './api.service';
export default {
  get(params) {
    return Api().get('/promo-codes', { params });
  },
  getById(id) {
    return Api().get(`/promo-codes/${id}`);
  },
  checkDuplicate(text) {
    return Api().get(`/promo-codes/duplicate/${text}`);
  },
  update(data) {
    return Api().put(`/promo-codes/${data._id}`, data);
  },
  save(data) {
    return Api().post('/promo-codes', data);
  },
  delete(id) {
    return Api().delete(`/promo-codes/${id}`);
  },
  updateMany(data) {
    return Api().put(`/promo-codes/update-many`, data);
  },
  getPromocodeCountByEventId(id) {
    return Api().get(`/promo-codes/events/count/${id}`);
  },
  exportExcel(params) {
    return Api().get('/promo-codes/export', {
      responseType: 'blob',
      params,
    });
  },
  validatePromocode(data) {
    return Api().post('/promo-codes/validate-promocode', data)
  }
};
