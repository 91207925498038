<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="100vh">
    <v-card min-height="50vh">
      <v-card-text>
        <v-form ref="forgotPasswordForm" v-model="form" lazy-validation>
          <v-card-title>Forgot password </v-card-title>

          <v-card-text>
            <v-text-field
              outlined
              type="email"
              name="email"
              label="Email"
              v-model="email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
            ></v-text-field>

            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block color="primary" :loading="loading.verify" @click="verify()">Request new password</v-btn>
              </v-col>
            </v-row>

            <a
              href="https://tickettext.zendesk.com/hc/en-us"
              target="__blank"
              class="d-block pt-8 text-center text-decoration-underline"
              >No email? Contact Support</a
            >
          </v-card-text>
        </v-form>
      </v-card-text>

      <tt-icon class="login-logo position-absolute justify-center ma-auto mb-4" width="50" icon="logo" />
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthService } from '@services';

export default {
  data() {
    return {
      dialog: false,
      form: false,
      code: null,
      email: null,
      loading: {
        verify: false,
        resend: false,
      },
    };
  },
  mounted() {
    this.$root.$on('forgot-password', () => {
      this.dialog = true;
    });
  },
  methods: {
    async verify() {
      this.loading.verify = true;
      if (this.$refs.forgotPasswordForm.validate()) {
        const response = await AuthService.forgotPassword({ email: this.email });
        if (response) {
          this.dialog = false;
        }
      }
      this.loading.verify = false;
    },
  },
};
</script>

<style scoped>
.bg-image {
  background: url('../../../../assets/images/promoter-login.svg') no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.login-logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
