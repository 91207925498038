import Api from './api.service';
export default {
  get(params) {
    return Api().get('/performers', { params });
  },
  getById(id) {
    return Api().get(`/performers/${id}`);
  },
  update(data) {
    return Api().put(`/performers/${data._id}`, data);
  },
  save(data) {
    return Api().post('/performers', data);
  },
  delete(id) {
    return Api().delete(`/performers/${id}`);
  },
  checkDuplicate(text) {
    return Api().get(`/performers/duplicate/${text}`);
  },
  checkDuplicateEmail(text) {
    return Api().get(`/performers/check-email/${text}`);
  },
  search(name) {
    return Api().get(`/performers/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/performers/export', {
      responseType: 'blob',
      params,
    });
  },
};
