import Api from './api.service';
export default {
  getStripeLink(accountId) {
    return Api().get(`/payments/stripe/link/${accountId}`);
  },
  getAccountInfo(accountId) {
    return Api().get(`/payments/stripe/account-info/${accountId}`);
  },
  createConnectedAccount(userId) {
    return Api().post(`/payments/stripe/create-account`, {userId});
  },
  createPaymentIntent(data) {
    return Api({ hideErrorMessage: true }).post(`/payments/stripe/create-payment-intent`, data);
  },
  payment(data) {
    return Api().post(`/payments/stripe/payment`, data);
  }
};
