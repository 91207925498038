import Api from './api.service';
export default {
  getByEvent(eventId) {
    return Api().get(`/event-survey/event/${eventId}`);
  },
  save(data) {
    return Api().post('/event-survey', data);
  },
  update(data) {
    return Api().put(`/event-survey/${data._id}`, data);
  },
  deleteByEvent(eventId) {
    return Api().delete(`/event-survey/event/${eventId}`);
  },
};
