import axios from 'axios';
import store from '@store';
import moment from 'moment-timezone';
import EventService from './event.service';

export default (options = {}) => {
  const config = {
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {},
  };

  const token = localStorage['auth.token']
  config.headers.timezone = moment.tz.guess();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(request => requestHandler(request));
  axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );

  const requestHandler = request => {
    if (request.method === 'get') {
      store.set('app/loading', true);
    }
    return request;
  };

  const errorHandler = ({ response }) => {
    store.set('app/loading', false);

    if (response && response.status == 401) {
      EventService.$emit('error', 'Your session has ended. Please log in again.');

      localStorage.removeItem('auth.token');

      store.set('auth/user', null);
      if(location.pathname.indexOf('admin') === 1 || location.pathname.indexOf('promoter') === 1){
        setTimeout(() => {
          location.href = '/';
        }, 1000); 
      } 
    } else {
      if (!options.hideErrorMessage && response && response.data) {
        const errorMessage = response.data.message || response.data.error || response.data;
        EventService.$emit('error', errorMessage);
      }
    }

    if (response && response.data && response.data.isUserNeedVerification) {
      return Promise.resolve(response.data);
    }
    if (options.hideErrorMessage && response && response.data) {
      return Promise.resolve(response);
    } else {
      return Promise.resolve(false);
    }
  };

  const successHandler = response => {
    store.set('app/loading', false);
    if (!options.hideSuccessMessage && response && response.data && response.data.message) {
      EventService.$emit('success', response.data.message);
    }
    return response.data;
  };

  return axiosInstance;
};
