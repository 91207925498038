export default [
  {
    path: '/venue',
    name: 'venue',
    component: () => import('./layout.vue'),
    children: [
      {
        path: '/',
        name: 'venue.root',
        redirect: '/venue/events',
      },
      {
        path: 'events',
        name: 'venue.events',
        component: () => import('./views/events/list.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/venue/events',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'events/callover-list/:id',
        name: 'venue.events.callover-list',
        component: () => import('./views/events/callover-list.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/venue/events',
              exact: true,
            },
            {
              text: 'Callover List',
              disabled: false,
              to: '/venue/events/callover-list',
            },
          ],
        },
      },
      {
        path: 'venues',
        name: 'venue.venues',
        component: () => import('./views/venues/list.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/venue/venues',
            },
          ],
        },
      },
      {
        path: 'order-history',
        name: 'venue.order-history',
        component: () => import('./views/order-history/list.vue'),
        meta: {
          title: 'Order History',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Order History',
              disabled: false,
              to: '/venue/order-history',
            },
          ],
        },
      },
      {
        path: 'account',
        name: 'venue.account.view',
        component: () => import('./views/accounts/view.vue'),
        meta: {
          title: 'Account Settings',
          icon: 'mdi-account-circle-outline',
          breadcrumbs: [
            {
              text: 'Account Details',
              disabled: false,
              to: '/venue/account',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'account/edit',
        name: 'venue.account.edit',
        component: () => import('./views/accounts/edit/index.vue'),
        meta: {
          title: 'Account Settings',
          icon: 'mdi-account-circle-outline',
          breadcrumbs: [
            {
              text: 'Account List',
              disabled: false,
              to: '/venue/account',
              exact: true,
            },
            {
              text: 'Edit Account',
              disabled: false,
              to: '/venue/account/edit',
            },
          ],
        },
      },
    ],
    meta: {
      auth: 'venue',
    },
  },
];
