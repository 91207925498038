import Api from './api.service';
export default {
  get(params) {
    return Api().get('/reports', { params });
  },
  getMarketing(params) {
    return Api().get('/reports/marketing-report', { params });
  },
  getMasterReport(data) {
    return Api().post('/reports/master-report', data);
  },
  exportMarketing(params) {
    return Api().get('/reports/marketing-report/export', { params, responseType: 'blob', });
  },
  exportMasterReport(data) {
    return Api().post('/reports/master-report/export', data, { responseType: 'blob' });
  },
  exportExcel(params) {
    return Api().get('/reports/export', {
      responseType: 'blob',
      params,
    });
  },
};
