import Api from './api.service';
export default {
  get(params) {
    return Api().get('/genres', { params });
  },
  getById(id) {
    return Api().get(`/genres/${id}`);
  },
  update(data) {
    return Api().put(`/genres/${data._id}`, data);
  },
  checkDuplicate(text) {
    return Api().get(`/genres/duplicate/${text}`);
  },
  save(data) {
    return Api().post('/genres', data);
  },
  delete(id) {
    return Api().delete(`/genres/${id}`);
  },
  search(name) {
    return Api().get(`/genres/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/genres/export', {
      responseType: 'blob',
      params,
    });
  },
};
