import Api from './api.service';
export default {
  get(params) {
    if (params.status === 'all' || params.status == 1 || params.status === 'my') {
      params.status = null;
    }
    return Api().get('/venues', { params });
  },
  getAll() {
    return Api().get('/venues/all');
  },
  getById(id) {
    return Api().get(`/venues/${id}`);
  },
  checkDuplicate(text) {
    return Api().get(`/venues/duplicate/${text}`);
  },
  update(data) {
    return Api().put(`/venues/${data._id}`, data);
  },
  approvedVenue(id) {
    return Api().put(`/venues/approved/${id}`);
  },
  rejectVenue(id) {
    return Api().put(`/venues/reject/${id}`);
  },
  save(data) {
    return Api().post('/venues', data);
  },
  delete(id) {
    return Api().delete(`/venues/${id}`);
  },
  exportExcel(params) {
    return Api().get('/venues/export', {
      responseType: 'blob',
      params,
    });
  },
};
