import Api from './api.service';
export default {
  get(params) {
    return Api().get('/seating-plans', { params });
  },
  getById(id) {
    return Api().get(`/seating-plans/${id}?forAdmin=1`);
  },
  update(data) {
    return Api().put(`/seating-plans/${data._id}`, data);
  },
  save(data) {
    return Api().post('/seating-plans', data);
  },
  delete(id) {
    return Api().delete(`/seating-plans/${id}`);
  },
  search(name) {
    return Api().get(`/seating-plans/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/seating-plans/export', {
      responseType: 'blob',
      params,
    });
  },
  exportReport(id) {
    return Api().post(`/tickets/seating-plan-report/${id}`, {}, {
      responseType: 'blob',
    });
  },
  exportTicketCodeReport(id) {
    return Api().post(`/tickets/ticket-codes-report/${id}`, {}, {
      responseType: 'blob',
    });
  },
};
