<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="100vh">
    <v-card min-height="50vh">
      <v-card-text>
        <!-- <v-row no-gutters style="height:90vh"> -->
        <!-- <v-col no-guttore cols="12" lg="6" class="position-relative"> -->
        <!-- <v-row justify="center">
        <v-col> -->
        <v-form ref="verifyForm" v-model="form" lazy-validation>
          <v-card-title>Please verify email address </v-card-title>

          <v-card-text>
            <v-text-field
              outlined
              type="text"
              name="code"
              label="Verification code"
              v-model="code"
              :rules="[v => !!v || 'Verification code is required']"
            ></v-text-field>

            <v-row>
              <v-col>
                <v-btn block :loading="loading.resend" @click="resend()">Resend</v-btn>
              </v-col>

              <v-col>
                <v-btn block color="primary" :loading="loading.verify" @click="verify()">Verify</v-btn>
              </v-col>
            </v-row>

            <a
              href="https://tickettext.zendesk.com/hc/en-us"
              target="__blank"
              class="d-block pt-8 text-center text-decoration-underline"
              >No email? Contact Support</a
            >
          </v-card-text>
        </v-form>
        <!-- </v-col>
      </v-row> -->
        <!-- </v-col> -->
        <!-- <v-col no-gutters cols="6" class="hidden-md-and-down">
          <div class="bg-image"></div>

          <v-btn
            fab
            icon
            right
            top
            absolute
            class="top-0"
            :color="$vuetify.breakpoint.mdAndDown ? 'primary' : 'white'"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col> -->
        <!-- </v-row> -->
      </v-card-text>

      <tt-icon class="login-logo position-absolute justify-center ma-auto mb-4" width="50" icon="logo" />
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthService } from '@services';

export default {
  data() {
    return {
      dialog: false,
      form: false,
      code: null,
      email: null,
      loading: {
        verify: false,
        resend: false,
      },
    };
  },
  mounted() {
    this.$root.$on('promoter-verify', email => {
      this.dialog = true;
      this.code = null;
      this.email = email;
    });
  },
  methods: {
    async resend() {
      this.loading.resend = true;

      if (this.email) {
        const response = await AuthService.resend({ email: this.email });

        if (response) {
          //
        }
      }

      this.loading.resend = false;
    },
    async verify() {
      this.loading.verify = true;

      if (this.$refs.verifyForm.validate()) {
        const response = await AuthService.verify({ code: this.code });
        if (response) {
          if (this.$route.name === 'visitor.event') {
            localStorage.setItem('isEventPageSignup', this.$route.params.id);
          }
          this.$login(response);
          this.$next({ name: 'welcome' });
          this.dialog = false;
        }
      }

      this.loading.verify = false;
    },
  },
};
</script>

<style scoped>
.bg-image {
  background: url('../../../../assets/images/promoter-login.svg') no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.login-logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
