var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","outlined":_vm.$attrs.outlined}},[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Login")]),_c('v-tab',[_vm._v("Sign up")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-form',{ref:"loginForm",attrs:{"lazy-validation":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()}},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}},[_c('v-card-title',[_vm._v("Login to TicketText ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"email","name":"name","label":"Email","rules":[
                  v => !!v || 'Email is required',
                  v => (v && !_vm.isFormSubmited) || /.+@.+\..+/.test(v) || 'Email must be valid',
                ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"outlined":"","dense":"","name":"name","label":"Password","rules":[v => !!v || 'Password is required']},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.email || !_vm.password,"loading":_vm.loading.login},on:{"click":function($event){return _vm.login()}}},[_vm._v("Login")]),_c('a',{staticClass:"d-block py-4 text-center text-decoration-underline",on:{"click":_vm.forgotpassword}},[_vm._v("I forgot my password")]),(_vm.isUserNeedVerification)?_c('div',{staticClass:"text-subtitle-2 d-block text-center"},[_vm._v(" Want to verify your account? "),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.verifyUser}},[_vm._v(" Click Here ")])]):_vm._e()],1)],1)],1)],1)],1),_c('v-tab-item',{staticClass:"px-0 px-sm-4"},[_c('v-form',{ref:"registerForm",attrs:{"lazy-validation":""},model:{value:(_vm.form.register),callback:function ($$v) {_vm.$set(_vm.form, "register", $$v)},expression:"form.register"}},[_c('v-card-title',[_vm._v("Welcome to Ticket Text ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","name":"name","label":"First name","rules":[
                  v => !!v || 'First name is required',
                  v => (v && v.length < 20) || 'First name must less than 20 character',
                ]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","name":"name","label":"Last name","rules":[
                  v => !!v || 'Last name is required',
                  v => (v && v.length < 20) || 'Last name must less than 20 character',
                ]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"email","name":"name","label":"Email","rules":[
                  v => !!v || 'Email is required',
                  v => /.+@.+\..+/.test(v) || 'Email must be valid',
                  v => _vm.isEmailDublicate || 'Email must be unique',
                ]},on:{"blur":function($event){return _vm.checkEmail()}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","name":"name","label":"Organiser name","rules":[
                  v => !!v || 'Organiser name is required',
                  v => (v && v.length < 50) || 'Last name must less than 50 character',
                ]},model:{value:(_vm.user.companyName),callback:function ($$v) {_vm.$set(_vm.user, "companyName", $$v)},expression:"user.companyName"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"outlined":"","dense":"","name":"name","label":"Create password","rules":[v => !!v || 'Password is required']},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show3 ? 'text' : 'password',"outlined":"","dense":"","name":"name","label":"Confirm password","rules":[
                  v => !!v || 'Confirm password is required',
                  v => v == _vm.user.password || 'Confirm password does not match',
                ]},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"rules":[v => !!v || '']},model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}},[_c('template',{slot:"label"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" By creating an account, I agree to the "),_c('a',{attrs:{"target":"_blank","href":"https://web-1.staging.tickettext.co.uk/terms"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Terms and Conditions ")]),_vm._v(" and "),_c('a',{attrs:{"target":"_blank","href":"https://web-1.staging.tickettext.co.uk/privacy"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Privacy policy. ")])])])],2),_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.agree || !_vm.confirmPassword || !_vm.form.register,"loading":_vm.loading.register},on:{"click":function($event){return _vm.register()}}},[_vm._v("Create account")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }