<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card min-height="30vh">
      <v-form ref="refundForm" v-model="form" lazy-validation>
        <v-card-title class="text-bold text-h6">Select Tickets </v-card-title>

        <v-card-text :key="key" class="pb-4 pb-sm-8">
          <v-row v-if="assignmentData && assignmentData.payment && assignmentData.payment.actualTicketPrice > 0">
            <v-col cols="12"> Price : {{ assignmentData.payment.actualTicketPrice | currency }} </v-col>
          </v-row>
          <v-row v-if="assignmentData && assignmentData.payment && assignmentData.payment.bookingFee > 0">
            <v-col cols="12"> Booking Fees : {{ assignmentData.payment.bookingFee | currency }} </v-col>
          </v-row>
          <v-row v-if="assignmentData && assignmentData.payment && assignmentData.payment.discount > 0">
            <v-col cols="12"> Discount : {{ assignmentData.payment.discount | currency }} </v-col>
          </v-row>
          <v-row v-if="assignmentData && assignmentData.payment && assignmentData.payment.amount > 0">
            <v-col cols="12"> Total Paid : {{ assignmentData.payment.amount | currency }} </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-bold text-h6"> Tickets</v-col>
          </v-row>
          <v-row v-for="item in tickets" :key="item._id">
            <v-col cols="1" sm="1" lg="1">
              <v-checkbox v-if="!item.isRefund" @click="checkTickets(item, item.isChecked)" v-model="item.isChecked"></v-checkbox>
            </v-col>
            <v-col v-if="item.eventTicketCategory.type === 'Reserved Seating'">
                <div>{{ item.seat }}</div>
            </v-col>
            <v-col v-if="item.eventTicketCategory.type !== 'Reserved Seating'">
              <div>{{ item.eventTicketCategory.category.name }}</div>
            </v-col>

            <v-col class="text-center text-sm-right">
              <div>Total: {{ (item.boughtBookingFee + item.boughtPrice) | currency }}</div>
            </v-col>

            <v-col class="text-center text-sm-right">
              <div>Paid: {{ (item.paidPrice || 0) | currency }}</div>
            </v-col>

            <!--v-col class="text-center text-sm-right">
              <div>Discount:{{ (item.discountPrice || 0) | currency }}</div>
            </v-col-->
          </v-row>

          <v-row v-if="refundAmount > 0" :key="refundAmount">
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col class="text-bold" cols="12"> Total Refund Amount: {{ refundAmount | currency }} </v-col>
          </v-row>
        </v-card-text>

        <v-spacer />
        <v-card-actions>
          <div class="mx-auto">
            <v-spacer />
            <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>

            <v-btn
              class="mx-1"
              width="150"
              :loading="loading"
              color="primary"
              @click="onClick()"
              >Refund</v-btn
            >
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { TicketCategories, PromoterEventService } from '@services';
export default {
  data() {
    return {
      dialog: false,
      event: null,
      key: 0,
      form: false,
      loading: false,
      assignmentData: null,
      tickets: [],
      refundAmount: 0,
      selectedIds: [],
      singleDiscountAmount: 0,
      applyDiscount: 0,
      overlay: false,
    };
  },
  mounted() {
    this.$root.$on('refund-ticket', data => {
      if (data) {
        this.dialog = true;
        this.assignmentData = data;
        this.overlay = true;
        this.tickets = [];
        this.refundAmount = 0;
        this.selectedIds = [];
        this.singleDiscountAmount = 0;
        this.applyDiscount = 0;
        this.getData();
      }
    });
  },
  methods: {
    async getData() {
      const tickets = await TicketCategories.getTicketsByAssignmentId(this.assignmentData._id);
      if (tickets) {
        this.assignmentData = tickets.assignmentData;
        this.tickets = tickets.data;
        for (let i =0; i < this.tickets.length; i += 1) {
          if (this.tickets[i].paidPrice === 0 || this.tickets[i].paidPrice > 0) {
            this.tickets[i].paidPrice = this.tickets[i].paidPrice;
          } else {
            if (this.assignmentData && this.assignmentData.payment && this.assignmentData.payment.discount) {
              console.log(this.tickets[i].boughtPrice, this.assignmentData.payment)
              const appliedDiscount = (this.tickets[i].boughtPrice * this.assignmentData.payment.discount) / this.assignmentData.payment.actualTicketPrice;
              this.tickets[i].paidPrice = (this.tickets[i].boughtPrice - appliedDiscount).toFixed(2);
              this.tickets[i].discountPrice = appliedDiscount.toFixed(2);
            } else {
              this.tickets[i].paidPrice = this.tickets[i].boughtPrice.toFixed(2);
            }
          }
        }
        if (this.assignmentData && this.assignmentData.payment && this.assignmentData.payment.discount > 0) {
          this.singleDiscountAmount = this.assignmentData.payment.discount / this.tickets.length;
        }
        this.key++;
      }
      this.overlay = false;
    },
    checkTickets(item, model) {
      if (model) {
        this.selectedIds.push({ id: item._id, amount: item.boughtPrice, paidPrice: item.paidPrice, fullAmount: item.boughtPrice + item.boughtBookingFee });
      } else {
        const ticketIndex = this.selectedIds.findIndex(e => e.id === item._id);
        if (ticketIndex > -1) {
          this.selectedIds.splice(ticketIndex, 1);
        }
      }

      this.refundAmount = 0;
      for (let index = 0; index < this.selectedIds.length; index++) {
        const element = this.selectedIds[index];
        element.paidPrice = Number(element.paidPrice)
        this.refundAmount = this.refundAmount + element.paidPrice;
        // if (element.paidPrice && element.paidPrice > 0) {
        //   this.refundAmount = this.refundAmount + element.paidPrice;
        // } else {
        //   if (this.assignmentData.payment.discount) {
        //     const oldDiscountPrice = ( element.fullAmount * this.assignmentData.payment.discount ) / (this.assignmentData.payment.actualTicketPrice + this.assignmentData.payment.bookingFee)
        //     console.log('element', element.amount, element.fullAmount, this.assignmentData.payment, oldDiscountPrice);
        //     this.refundAmount = this.refundAmount + (element.fullAmount - oldDiscountPrice);
        //   } else {
        //     this.refundAmount = this.refundAmount + (element.amount);
        //   }
        // }
      }
    },
    async onClick() {
      if (this.$refs.refundForm.validate() && this.selectedIds.length > 0) {
        this.loading = true;
        const res = await PromoterEventService.refundTicket(this.assignmentData._id,{ ticketsIds: this.selectedIds})   
        this.loading = false;
        if (res) {
          this.$root.$emit('fetch-ticket');
          this.dialog = false;
        }
        
      }
    },
    onCancel() {
      this.dialog = false;
    },
  },
};
</script>
