import slugify from 'slugify';
import { EventService, UserService, UserActivityService } from '@services';
import moment from 'moment';
import momenttz from 'moment-timezone';

export default {
  computed: {
    $user() {
      return this.$store.state.auth.user;
    },
    $state() {
      return this.$store.state.app.state;
    },
  },
  methods: {
    $next(to) {
      this.$router.push(to).catch(() => { });
    },
    $setState(state) {
      this.$state[this.$route.name] = state;
      this.$store.set('app/state', this.$state);
    },
    $getState() {
      return this.$state[this.$route.name] || {};
    },
    $success(message) {
      this.$bus.$emit('success', message);
    },
    $error(message) {
      this.$bus.$emit('error', message);
    },
    $alert(options) {
      this.$root.$emit('alert', options);
    },
    $confirm(options) {
      this.$root.$emit('confirm', options);
    },
    $ticket(options) {
      this.$root.$emit('ticket', options);
    },
    $copy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$success('Copying to clipboard was successful!');
        },
        err => {
          this.$error('Could not copy text: ', err);
        }
      );
    },
    $scrollToTop() {
      window.scrollTo(0, 0);
    },
    $slugify(string) {
      return slugify(string, {
        lower: true,
      });
    },
    $getAddonImage(addonId, key) {
      return `${process.env.VUE_APP_S3_URL}/addon/${addonId}/original/${key}`;
    },
    $getAddon200Image(addonId, key) {
      if(key && addonId) {
        return `${process.env.VUE_APP_S3_URL}/addon/${addonId}/200/${key}`;
      } else {
        return `${process.env.VUE_APP_S3_URL}/addon/default`
      }
    },
    $getImage(key) {
      return `${process.env.VUE_APP_API_URL}/event-images/thumb/${key}.jpg`;
    },
    $getProfile200Image(userId, key) {
      return `${process.env.VUE_APP_S3_URL}/${userId}/profile/200/${key}`;
    },
    $get1800Image(key, imageUserId, eventId) {
      if (key && imageUserId) {
        return `${process.env.VUE_APP_S3_URL}/${imageUserId}/${eventId}/1800/${key}`;
      } else {
        return `${process.env.VUE_APP_S3_URL}/event/default`
      }

    },
    $getOrignalImage(key, imageUserId, eventId) {
      if (key && imageUserId) {
        return `${process.env.VUE_APP_S3_URL}/${imageUserId}/${eventId}/original/${key}`;
      } else {
        return `${process.env.VUE_APP_S3_URL}/event/default`
      }
    },
    $get400Image(key, imageUserId, eventId) {
      if (key && imageUserId) {
        return `${process.env.VUE_APP_S3_URL}/${imageUserId}/${eventId}/1200/${key}`;
      } else {
        return `${process.env.VUE_APP_S3_URL}/event/default`
      }
    },
    $getOrignalImageByWidth(key, imageUserId, eventId, width) {
      if (key && imageUserId) {
        return `${process.env.VUE_APP_S3_URL}/${imageUserId}/${eventId}/${width}/${key}`;
      } else {
        return `${process.env.VUE_APP_S3_URL}/event/default`
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    preventLeadingSpace(e) {
      if (!e.target.value) { 
        e.preventDefault(); 
      } else {
        e.target.value = ` ${e.target.value}`;
        e.target.value = e.target.value.trim();
      }
    },
    isFaceValue(evt) {
      evt = evt ? evt : window.event;
      console.log("🚀 ~ file: app.mixin.js ~ line 79 ~ isFaceValue ~ evt", evt)
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    isCapital(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    isPromocode(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    $scrollToElement(elem_id) {
      console.log("🚀 ~ file: app.mixin.js ~ line 133 ~ $scrollToElement ~ elem_id", elem_id)
      let element = document.getElementById(elem_id);
      console.log("🚀 ~ file: app.mixin.js ~ line 134 ~ $scrollToElement ~ element", element)
      const headerOffset = 75;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    newTab(to) {
      const route = this.$router.resolve(to);
      window.open(route.href, '_blank');
    },
    shortQueryParams(filters) {
      let searchParams = "";
      if (Object.keys(filters).length) {
        const searchMapping = {
          'search': 'se',
          'weekdays': 'we',
          'venue': 've',
          'genre': 'ge',
          'filterEventDate': 'da'
        };
        for (let [key, value] of Object.entries(filters)) {
          if (key === "isSeeMore" || key === "eventtype" || key === "eventStarts" || key === "eventEnds" || key === "date") {
            continue;
          }
          if(key === 'filterEventDate' && value && (!value.length || (value.length && !value[0]))) {
            value = '';
          }
          if (searchParams === "" && value) {
            searchParams = "?" + searchMapping[key] + "=" + value;
          }
          else if (searchParams !== "" && value) {
            searchParams += "&" + searchMapping[key] + "=" + value;
          }
        }
      }
      return searchParams;
    },
    showKYCNotification(striperesponse, stripeLinkresponse, showNotification = true) {
      let stripeKYCStatus = true;
      Object.keys(striperesponse.data.capabilities).map((capData) => {
        striperesponse.data.capabilities[capData] ===  'inactive' ? stripeKYCStatus = false : null;
      })
      if (striperesponse && stripeLinkresponse && striperesponse.data && !striperesponse.data.details_submitted && !stripeKYCStatus && showNotification) {
        EventService.$emit('user-kyc', 'We need you to add some info to Stripe so we can <br/>verify your payment details. <br />Please click the "Complete KYC" button to start, <br />and follow the on screen instructions.', 'user-kyc', stripeLinkresponse);
      } else if (striperesponse && stripeLinkresponse && striperesponse.data && !striperesponse.data.details_submitted && stripeKYCStatus && showNotification) {
        EventService.$emit('add-bank', 'Please add your preferred bank account details for payments <br /> by clicking the button below.', 'add-bank', stripeLinkresponse);
      } 
      return stripeKYCStatus;
    },
    $validateDateRange(dates) {
      let isValidDate = true;
      var from = Date.parse(dates[0]);
      var to = Date.parse(dates[1]);
      if (from > to) {
        isValidDate = false;
        EventService.$emit('error', 'Please select valid dates');
      }
      return isValidDate;
    },
    $parseTransDateFormat(data) {
      let fieldData;
      if(data.length === 0) {
        return '';
      }
      if(data.length === 1) {
        return data[0];
      }
      fieldData = data[0] + " - " + data[1];
      return fieldData;
    },
    securityConfirm() {
      this.$alert({
        title: `Please change your security settings to allow the extension "https://js.stripe.com" to process your payment. <p> If you require any further assistance then please contact us at contact@tickettext.co.uk.`,
        resolve: async () => {
          
        },
        iconName: 'error'
      });
    },
    addEventCalendarOptions() {
      return [
        { title: 'Outlook Calendar', id:"outlook", icon: "mdi-email" },
        { title: 'Google Calendar', id:"google", icon: "mdi-calendar" },
        { title: 'Apple Calendar', id:"apple", icon: "mdi-apple" },
        { title: 'Yahoo Calendar', id:"yahoo", icon: "mdi-yahoo" },
      ];
    },
    async downloadICSFile() {
      const exportdata = await UserService.downloadICSFile({id: this.event._id});
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `calendar.ics`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
    async addEventToCalender(item, event) {
      const title = event.title;
      const descr = event.blurbMain;
      const venue = event.venue ? event.venue.name :'';
      const startDate = moment(event.startsAt).format('YYYYMMDDTHHmmssZ');
      const endDate = moment(event.endsAt).format('YYYYMMDDTHHmmssZ');
      const timezone = event.timezone;
      let starts = moment(event.startsAt);
      let ends = moment(event.endsAt);

      let minsDiff = ends.diff(starts, 'minutes');
      let hrs = Math.floor(minsDiff / 60);
      let mins = minsDiff % 60;
      let finalDuration = hrs+this.pad(mins);
      switch(item.id) {
        case "google": 
          window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&details=${descr}&location=${venue}&dates=${startDate}/${endDate}&ctz=${timezone}`, "_blank");
          break;
        case "yahoo":
          let descrWithoutHtml =  descr.replace(/<\/?[^>]+(>|$)/g, "");
          window.open(`https://login.yahoo.com/?.src=yc&.done=https%3A%2F%2Fcalendar.yahoo.com%2F%3Fv%3D60%26view%3Dd%26type%3D20%26title%3D${title}%26st%3D${startDate}%26dur%3D0${finalDuration}%26desc%3D${descrWithoutHtml}%26in_loc%3D${venue}`, "_blank");
          break;
        case "apple":
        case "outlook":
          await this.downloadICSFile();
          break;
        default:
          break;
      }
    },
    getCustomizedMailContent() {
      return `<p>This is a message regarding your ticket purchase for the following event:-</p><br /><strong>Event Details:</strong><br />Event Name: &lt;event-name&gt;
      <br />Venue: &lt;venue-name&gt;<br />Event Date: &lt;event-date&gt;<br />Doors Time: &lt;door-time&gt;<br /><br /><p>Add message here.</p><br /><p>Thanks for using TicketText. If you have any queries about the above message or questions regarding your purchase then please contact your TicketText rep or send us an email at info@tickettext.co.uk and we will be happy to assist.</p><br />Best wishes,<br />TicketText Team.`;
    },
    pad(n) {
      return (n < 10) ? ("0" + n) : n;
    },
    randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    manageUserActivity(current_event_obj) {
      let userId = '';
      if(this.$user && this.$user._id) {
        userId = this.$user._id || '';
      }
      let currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
      let currentTimeZone = momenttz.tz.guess();
      let userActivityData = JSON.parse(localStorage.getItem('user_'+userId));
      current_event_obj['time'] = currentTime;
      current_event_obj['timezone'] = currentTimeZone;
      if(!userActivityData) {
        localStorage.setItem("user_"+userId, JSON.stringify([current_event_obj]));
      } else {
        userActivityData.push(current_event_obj);
        localStorage.setItem("user_"+userId, JSON.stringify(userActivityData));
      }
    },
    logUserActivity() {
      let userId = this.$user._id || '';
      let userActivityData = JSON.parse(localStorage.getItem('user_'+userId));
      if(userActivityData) {
        let data = {
          user: userId,
          activity: userActivityData,
        }
        UserActivityService.logUserActivity(data);
        localStorage.removeItem("user_"+userId);
      }
    }
  },
};
