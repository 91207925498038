<template>
  <div>
    <v-card-subtitle class="py-0 text-center text-bold">Or</v-card-subtitle>
    <v-card-actions>
      <v-row justify="center">
        <v-col cols="12" sm="9" md="8">
          <v-btn large block color="#3b5998" class="white--text" :loading="loading.facebook" @click="$emit('facebook')">
            <v-icon class="mr-2" color="white">mdi-facebook</v-icon>
            Sign {{ signup ? 'up' : 'in' }} with Facebook
          </v-btn>
        </v-col>
        <v-col cols="12" sm="9" md="8">
          <v-btn large block color="#fff" :loading="loading.google" @click="$emit('google')">
            <v-icon class="mr-2" color="primary">mdi-google</v-icon>
            Sign {{ signup ? 'up' : 'in' }} with Google
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Object, required: true },
    signup: { type: Boolean, default: false },
  },
};
</script>
