import Api from './api.service';
export default {
  get(params) {
    return Api().get('/seatsio-charts', { params });
  },
  getById(id) {
    return Api().get(`/seatsio-charts/${id}`);
  },
  update(data) {
    return Api().put(`/seatsio-charts/${data._id}`, data);
  },
  save(data) {
    return Api().post('/seatsio-charts', data);
  },
  delete(id) {
    return Api().delete(`/seatsio-charts/${id}`);
  },
  checkDuplicate(text) {
    return Api().get(`/seatsio-charts/duplicate/${text}`);
  },
  search(name) {
    return Api().get(`/seatsio-charts/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/seatsio-charts/export', {
      responseType: 'blob',
      params,
    });
  },
};
