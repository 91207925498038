<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500px" >
    <v-card >
      <Base @close="dialog = false" :activeTab="activeTab" />
      <tt-icon class="mx-auto pb-8" width="50" icon="logo" />
    </v-card>
  </v-dialog>
</template>

<script>
import Base from './base';

export default {
  components: {
    Base,
  },
  data() {
    return {
      dialog: false,
      activeTab: 0
    };
  },
  mounted() {
    this.$root.$on('visitor-login', (data) => {
      if (data && data === 'homePage') {
        this.activeTab = 1;
      } else {
        this.activeTab = 0;
      }
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.login-logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
