<template>
  <v-app>
    <v-progress-linear :active="loading" indeterminate top fixed value="15"></v-progress-linear>

    <router-view></router-view>

    <login-dialog />
    <verify-dialog />
    <forgot-password />
    <snackbar />
    <alert-dialog />
    <confirm-dialog />
    <input-dialog />
    <event-dailog />
    <refund-dailog />
    <performer-dailog />
    <promoter-dialog />
    <count-list-dailog />
    <snackbar-v2 />

  </v-app>
</template>

<script>
import { sync } from 'vuex-pathify';
import AlertDialog from '@/components/alert-dialog';
import ConfirmDialog from '@/components/confirm-dialog';
import InputDialog from '@/components/input-dailog.vue';
import LoginDialog from '@visitor/views/auth/login.dialog.vue';
import PromoterDialog from '@promoter/views/auth/login.dialog.vue';
import VerifyDialog from '@promoter/views/auth/verify.dialog.vue';
import ForgotPassword from '@promoter/views/auth/forgot-password.dialog.vue';
import CountListDailog from '@promoter/views/promo-codes/count-list.dailog.vue';
import EventDailog from '@admin/views/events/event-list-dialog.vue';
import PerformerDailog from '@visitor/views/event/performers-dialog.vue';
import RefundDailog from '@admin/views/events/refund-dialog.vue';
import Snackbar from '@/components/snackbar';
import SnackbarV2 from '@/components/snackbar-v2';
import { UserService, AppVersionService, EventService, PaymentService } from '@services';

export default {
  components: {
    LoginDialog,
    VerifyDialog,
    AlertDialog,
    ConfirmDialog,
    Snackbar,
    ForgotPassword,
    InputDialog,
    EventDailog,
    RefundDailog,
    PerformerDailog,
    PromoterDialog,
    CountListDailog,
    SnackbarV2
  },
  mounted() {
    const app_version = localStorage.getItem('app_version');
    const cookies_consent = localStorage.getItem('cookies_consent') || '';
    if(cookies_consent !== 'accepted') {
      EventService.$emit('cookie-consent', `By clicking “Accept all cookies”, you agree TicketText can store cookies on your device and disclose information in accordance with our <a target="_blank" href='${process.env.VUE_APP_APP_URL}privacy?q=cookie'>Cookie Policy<a/>.`, 'cookie-consent');
    }
    if(app_version === null) {
      localStorage.setItem('app_version', process.env.VUE_APP_VERSION);
    }
    this.getCurrentAppVersion();
    //this.checkStripeKYC();
  },
  computed: {
    ...sync('app', ['loading']),
  },
  methods: {
    async getUser() {
      if (this.$user?.organiser) {
        const response = await UserService.getOrganiserInfo(this.$user.organiser);
        if (response) {
          this.$store.set('auth/user', response);
        }
      }
    },
    async getCurrentAppVersion() {
      const currentAppVersion = await AppVersionService.getVersion();
      const appversion = localStorage.getItem("app_version");
      if(currentAppVersion.appVersion !== appversion) {
        localStorage.setItem('new_app_version', currentAppVersion.appVersion);
        EventService.$emit('app-version', 'We have updated the website. Reload is required to <br />apply changes.','app-version');
      }
    },
    async checkStripeKYC() {
      let user = await UserService.me();
      if (user && user.role === 'promoter' && user.stripe && user.stripe.accountId) {
        const striperesponse = await PaymentService.getAccountInfo(user.stripe.accountId);
        const stripeLinkresponse = await PaymentService.getStripeLink(user.stripe.accountId);
        if (striperesponse && stripeLinkresponse && striperesponse.data && !striperesponse.data.details_submitted && !striperesponse.data.payouts_enabled) {
          EventService.$emit('user-kyc', 'We need you to add some info to Stripe so we can <br/>verify your payment details. <br />Please click the "Complete KYC" button to start, <br />and follow the on screen instructions.', 'user-kyc', stripeLinkresponse);
        }
      }
    }
  },
};
</script>

<style lang="scss" src="@/scss/main.scss"></style>

<style lang="scss" src="@/scss/vuetify.scss"></style>