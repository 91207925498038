<template >
  <v-snackbar class="pa-2" elevation="5" :auto-height="true" app right  multi-line :color="color" v-model="snackbar" :timeout="timeout">
    <div :class="textColorWithMargin">
      <strong v-if="action==='app-version'">New version available</strong>
      <strong v-if="action==='user-kyc'">KYC is not completed.</strong>
      <strong v-if="action==='add-bank'">Add Bank Details</strong>
      <strong v-if="action==='show-kyc'">KYC is not completed.</strong>
      <strong v-if="action==='cookie-consent'">Accept cookies</strong>
    </div>
    <span :class="textColor" v-html="text">{{action}}</span>
    <div class="mt-4 mb-2">
      <v-btn v-if="action==='app-version'" class="primary" width=150 v-bind="attrs" @click="refreshPage()">
        Reload the page
      </v-btn>
      <v-btn v-if="action==='user-kyc'" :disabled="!data.url" class="primary" width=250 :href="data.url" target="_blank">
        <v-icon class="mr-2" color="white">mdi-lock</v-icon>Complete KYC
      </v-btn>
      <v-btn v-if="action==='add-bank'" :disabled="!data.url" class="primary" width=200 @click="closeSnackBar()" :to="{ name: 'promoter.account.edit', params: { tab: 1 } }">
        Add Bank Details
      </v-btn>
      <v-btn v-if="action==='cookie-consent'" class="primary" width=200 @click="acceptCookies()">
        Accept all cookies
      </v-btn>
    </div>
    <template v-slot:action="{ attrs }" v-if="action==='user-kyc' || action==='show-kyc' || action === 'add-bank'">
      <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false"  v-if="action==='user-kyc' || action === 'add-bank'">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn color="white" icon v-bind="attrs" @click="snackbar = false"  v-if="action==='show-kyc'">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import StripeMixin from '@mixins/stripe.mixin';
export default {
  name: 'Snackbar',
  //mixins: [StripeMixin],
  data() {
    return {
      snackbar: false,
      text: '',
      color: '',
      timeout: -1,
      action: '',
      data: {},
    };
  },
  computed: {
    textColorWithMargin() {
      if(this.action === 'show-kyc') {
        return "white--text mb-1";
      }
      return "black--text mb-1";
    },
    textColor() {
      if(this.action === 'show-kyc') {
        return "white--text";
      }
      return "black--text";
    }
  },
  methods: {
    refreshPage() {
      const updatedAppVersion = localStorage.getItem("new_app_version");
      if(updatedAppVersion !== null) {
        localStorage.setItem('app_version', updatedAppVersion);
      }
      localStorage.removeItem('new_app_version');
      window.location.reload(true);
    },
    closeSnackBar(){
      this.snackbar = false;
    },
    acceptCookies() {
      localStorage.setItem('cookies_consent', 'accepted');
      this.snackbar = false;
    }
  },
  mounted() {
    this.$bus.$on('close-snackbar', text => {
      this.snackbar = false;
    });
    this.$bus.$on('app-version', (text, action) => {
      this.snackbar = true;
      this.color = 'white';
      this.text = text;
      this.action = action;
    });
    this.$bus.$on('user-kyc', (text, action, responseData) => {
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 56 ~ this.$bus.$on ~ action", action)
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 56 ~ this.$bus.$on ~ text", text)
      this.snackbar = true;
      this.color = 'white';
      this.text = text;
      this.action = action;
      this.data = responseData;
    });
    this.$bus.$on('add-bank', (text, action, responseData) => {
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 66 ~ this.$bus.$on ~ action", action)
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 67 ~ this.$bus.$on ~ text", text)
      this.snackbar = true;
      this.color = 'white';
      this.text = text;
      this.action = action;
      this.data = responseData;
    });
    this.$bus.$on('show-kyc', (text, action, responseData) => {
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 79 ~ this.$bus.$on ~ action", action)
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 80 ~ this.$bus.$on ~ text", text)
      this.snackbar = true;
      this.color = 'error';
      this.text = text;
      this.action = action;
      this.data = responseData;
    });
    this.$bus.$on('cookie-consent', (text, action) => {
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 111 ~ this.$bus.$on ~ action", action)
      console.log("🚀 ~ file: snackbar-v2.vue ~ line 112 ~ this.$bus.$on ~ text", text)
      this.snackbar = true;
      this.color = 'white';
      this.text = text;
      this.action = action;
      this.data = [];
    });
  },
};
</script>