<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="items"
    item-text="suggestion"
    item-value="udprn"
    :search-input.sync="search"
    @change="onChange()"
    dense
    outlined
    hide-no-data
    label="Search your address"
  ></v-autocomplete>
</template>

<script>
import axios from 'axios';

export default {
  name: 'postcode',
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    async onChange() {
      if (this.select) {
        const response = await axios.get(`https://api.ideal-postcodes.co.uk/v1/udprn/${this.select}`, {
          params: {
            api_key: process.env.VUE_APP_POSTCODE_KEY,
          },
        });

        if (response?.data?.result) {
          const address = {};
          address.line1 = response.data.result.line_1;
          address.line2 = response.data.result.line_2;
          address.city = response.data.result.post_town;
          address.county = response.data.result.county;
          address.postcode = response.data.result.postcode;
          address.country = response.data.result.country;
          address.latitude = response.data.result.latitude;
          address.longitude = response.data.result.longitude;

          this.$emit('change', address);
        }
      }
    },
    async querySelections(query) {
      this.loading = true;

      const response = await axios.get('https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses', {
        params: {
          query,
          api_key: 'iddqd',
        },
      });

      if (response) {
        this.items = response.data.result.hits;
      }

      this.loading = false;
    },
  },
};
</script>
