import Api from './api.service';
export default {
  upload(file) {
    let formData = new FormData();
    formData.append('media', file);
    formData.append('key', file.key);
    return Api().post('media/upload-form-data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadimage(name, params) {
    let formData = new FormData();
    formData.append('file', params.file);
    return Api().put(`/event-images/event/${name}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadUserimage(name, params) {
    let formData = new FormData();
    formData.append('file', params.file);
    return Api().put(`/event-images/organiser/${name}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadAddonimage(name, params, addonId) {
    let formData = new FormData();
    formData.append('file', params.file);
    return Api().put(`/event-images/addon/${name}?id=${addonId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  removeUserimage(name) {
    return Api().delete(`/event-images/organiser/${name}`);
  },
  removeimage(name) {
    return Api().delete(`/event-images/event/${name}`);
  }
};
