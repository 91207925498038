<template>
  <v-snackbar app right multi-line :color="color" v-model="snackbar" :timeout="timeout">
    <v-icon v-if="color == 'success'">mdi-check-circle-outline</v-icon>
    <v-icon v-if="color == 'error'">mdi-close-circle-outline</v-icon>
    <v-icon v-if="color == 'primary'"></v-icon>
    <span> {{ text }}</span>
    
    <template v-slot:action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      
    </template>

  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      snackbar: false,
      text: '',
      color: '',
      timeout: 5000,
    };
  },
  mounted() {
    this.$bus.$on('success', text => {
      this.snackbar = true;
      this.color = 'success';
      this.text = text;
    });

    this.$bus.$on('error', text => {
      this.snackbar = true;
      this.color = 'error';
      this.text = text;
    });

  },
};
</script>
