import Api from './api.service';
export default {
  get(params) {
    return Api().get('/organisers', { params });
  },
  getById(id) {
    return Api().get(`/organisers/${id}`);
  },
  update(data) {
    return Api().put(`/organisers/${data._id}`, data);
  },
  save(data) {
    return Api().post('/organisers', data);
  },
  delete(id) {
    return Api().delete(`/organisers/${id}`);
  },
  search(name) {
    return Api().get(`/organisers/search/${name}`);
  },
  checkDuplicate(text) {
    return Api().get(`/organisers/duplicate/${text}`);
  },
  checkDuplicateSlug(text) {
    return Api().get(`/organisers/check-slug-duplicate/${text}`);
  },
  exportExcel(params) {
    return Api().get('/organisers/export', {
      responseType: 'blob',
      params,
    });
  },
};
