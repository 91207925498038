function signInWithPopup(provider) {
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      return result;
    })
    .catch(function(error) {
      console.error(error);
      return false;
    });
}

export default {
  initializeApp(){
    var firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    };

    firebase.initializeApp(firebaseConfig);
  },
  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return signInWithPopup(provider);
  },
  signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return signInWithPopup(provider);
  },
  signInWithTwitter() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return signInWithPopup(provider);
  },
};
