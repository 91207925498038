import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './plugins';
import './components';
import './mixins';
import './filters';

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, { id: 'UA-38956152-1', router });

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import UUID from "vue-uuid";
Vue.use(UUID);


Vue.config.productionTip = false;

// Vue.prototype.$bus = new Vue();
Vue.config.ignoredElements = ['vue-widget']

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
