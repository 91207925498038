export default [
  {
    path: '/promoter/login',
    name: 'promoter.login',
    component: () => import(/* webpackChunkName: "promoter.login" */ './views/auth/login.vue'),
  },
  {
    path: '/promoter/register',
    name: 'promoter.register',
    component: () => import(/* webpackChunkName: "promoter.register" */ './views/auth/login.vue'),
    props: {
      activeTab: 1,
    },
  },
  {
    path: '/promoter/reset-password',
    name: 'promoter.reset-password',
    component: () => import(/* webpackChunkName: "promoter.reset-password" */ './views/auth/reset-password.vue'),
  },
  {
    path: '/promoter',
    name: 'promoter',
    component: () => import(/* webpackChunkName: "promoter" */ './layout.vue'),
    children: [
      {
        path: '/',
        name: 'promoter.root',
        redirect: '/promoter/dashboard',
      },
      {
        path: 'dashboard',
        name: 'promoter.dashboard',
        component: () => import(/* webpackChunkName: "promoter.dashboard" */ './views/dashboard/index.vue'),
      },
      {
        path: 'events/dashboard/:id',
        name: 'promoter.events.dashboard',
        component: () => import(/* webpackChunkName: "promoter.events" */ './views/events/dashboard/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
            {
              text: 'Event Details',
              disabled: false,
              to: '/promoter/events/dashboard',
            },
          ],
        },
      },
      {
        path: 'events',
        name: 'promoter.events',
        component: () => import(/* webpackChunkName: "promoter.events" */ './views/events/list.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'events/new',
        name: 'promoter.events.add',
        component: () => import('./views/events/edit/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calender',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
            {
              text: 'Add Event',
              disabled: false,
              to: '/promoter/events/new',
            },
          ],
          type: 'edit',
        },
      },
      {
        path: 'events/edit/:id/:step?',
        name: 'promoter.events.edit',
        component: () => import('./views/events/edit/index.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calender',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
            {
              text: 'Edit Event',
              disabled: false,
              to: '/promoter/events/edit',
            },
          ],
          type: 'edit',
        },
      },
      {
        path: 'events/callover-list/:id',
        name: 'promoter.events.callover-list',
        component: () => import(/* webpackChunkName: "promoter.events" */ './views/events/callover-list.vue'),
        meta: {
          title: 'Events',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
            {
              text: 'Callover List',
              disabled: false,
              to: '/promoter/events/callover-list',
            },
          ],
        },
      },
      {
        path: 'events/survey-report/:id',
        name: 'promoter.events.survey-report',
        component: () => import(/* webpackChunkName: "admin.events" */ '@admin/views/events/survey-report.vue'),
        meta: {
          title: 'Event',
          icon: 'mdi-calendar',
          breadcrumbs: [
            {
              text: 'Event List',
              disabled: false,
              to: '/promoter/events',
              exact: true,
            },
            {
              text: 'Survey Report',
              disabled: false,
              to: '/promoter/events/survey-report',
            },
          ],
        },
      },
      {
        path: 'venues',
        name: 'promoter.venues',
        component: () => import(/* webpackChunkName: "promoter.venues" */ './views/venues/list.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/promoter/venues',
            },
          ],
        },
      },
      {
        path: 'venues/view/:id',
        name: 'promoter.venues.view',
        component: () => import(/* webpackChunkName: "promoter.venues" */ './views/venues/view.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/promoter/venues',
              exact: true,
            },
            {
              text: 'Venue Details',
              disabled: false,
              to: '/promoter/venues/view',
            },
          ],
        },
      },
      {
        path: 'venues/new',
        name: 'promoter.venues.add',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/venues/edit.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/promoter/venues',
              exact: true,
            },
            {
              text: 'Add Venue',
              disabled: false,
              to: '/promoter/venues/new',
            },
          ],
        },
      },
      {
        path: 'venues/edit/:id',
        name: 'promoter.venues.edit',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/venues/edit.vue'),
        meta: {
          title: 'Venues',
          icon: 'mdi-map-marker-outline',
          breadcrumbs: [
            {
              text: 'Venue List',
              disabled: false,
              to: '/promoter/venues',
              exact: true,
            },
            {
              text: 'Edit Venue',
              disabled: false,
              to: '/promoter/venues/edit',
            },
          ],
        },
      },
      {
        path: 'promo-codes',
        name: 'promoter.promo-codes',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/list.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promo Code List',
              disabled: false,
              to: '/promoter/promo-codes',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'promo-codes/new',
        name: 'promoter.promo-codes.add',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/edit.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promo Code List',
              disabled: false,
              to: '/promoter/promo-codes',
              exact: true,
            },
            {
              text: 'Add Promo Code',
              disabled: false,
              to: '/promoter/promo-codes/new',
            },
          ],
        },
      },
      {
        path: 'promo-codes/edit/:id',
        name: 'promoter.promo-codes.edit',
        component: () => import(/* webpackChunkName: "promoter.promo-codes" */ './views/promo-codes/edit.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Promocode List',
              disabled: false,
              to: '/promoter/promo-codes',
              exact: true,
            },
            {
              text: 'Edit Promocode',
              disabled: false,
              to: '/promoter/promo-codes/edit',
            },
          ],
        },
      },
      {
        path: 'survey',
        name: 'promoter.survey',
        component: () => import('./views/survey/list.vue'),
        meta: {
          title: 'Survey',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/promoter/survey',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'survey/new',
        name: 'promoter.survey.add',
        component: () => import('./views/survey/edit.vue'),
        meta: {
          title: 'Survey',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/promoter/survey',
              exact: true,
            },
            {
              text: 'Add Survey',
              disabled: false,
              to: '/promoter/survey/new',
            },
          ],
        },
      },
      {
        path: 'survey/edit/:id',
        name: 'promoter.survey.edit',
        component: () => import('./views/survey/edit.vue'),
        meta: {
          title: 'Promo Codes',
          icon: 'mdi-file-outline',
          breadcrumbs: [
            {
              text: 'Survey List',
              disabled: false,
              to: '/promoter/survey',
              exact: true,
            },
            {
              text: 'Edit Survey',
              disabled: false,
              to: '/promoter/survey/edit',
            },
          ],
        },
      },
      {
        path: 'add-ons',
        name: 'promoter.add-ons',
        component: () => import(/* webpackChunkName: "promoter.add-ons" */ './views/add-ons/list.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/promoter/add-ons',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'add-ons/new',
        name: 'promoter.add-ons.add',
        component: () => import(/* webpackChunkName: "promoter.add-ons" */ './views/add-ons/edit/index.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/promoter/add-ons',
              exact: true,
            },
            {
              text: 'Add Add Ons',
              disabled: false,
              to: '/promoter/add-ons/new',
            },
          ],
        },
      },
      {
        path: 'add-ons/edit/:id',
        name: 'promoter.add-ons.edit',
        component: () => import(/* webpackChunkName: "promoter.add-ons" */ './views/add-ons/edit/index.vue'),
        meta: {
          title: 'Add Ons',
          icon: 'mdi-plus-outline',
          breadcrumbs: [
            {
              text: 'Add Ons List',
              disabled: false,
              to: '/promoter/add-ons',
              exact: true,
            },
            {
              text: 'Edit Add Ons',
              disabled: false,
              to: '/promoter/add-ons/edit',
            },
          ],
        },
      },
      {
        path: 'account',
        name: 'promoter.account.view',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/accounts/view.vue'),
        meta: {
          title: 'Account Settings',
          icon: 'mdi-account-circle-outline',
          breadcrumbs: [
            {
              text: 'Account Details',
              disabled: false,
              to: '/promoter/account',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'account/edit',
        name: 'promoter.account.edit',
        component: () => import(/* webpackChunkName: "promoter.accounts" */ './views/accounts/edit/index.vue'),
        meta: {
          title: 'Account Settings',
          icon: 'mdi-account-circle-outline',
          breadcrumbs: [
            {
              text: 'Account List',
              disabled: false,
              to: '/promoter/account',
              exact: true,
            },
            {
              text: 'Edit Account',
              disabled: false,
              to: '/promoter/account/edit',
            },
          ],
        },
      },
      {
        path: 'reports',
        name: 'promoter.reports',
        component: () => import(/* webpackChunkName: "promoter.reports" */ './views/reports/reports.vue'),
        meta: {
          title: 'Reports',
          icon: 'mdi-tag-outline',
          breadcrumbs: [
            {
              text: 'Reports',
              disabled: false,
              to: '/promoter/reports',
              exact: true,
            },
          ],
        },
      },
      {
        path: 'sales-reports',
        name: 'promoter.sales-reports',
        component: () => import(/* webpackChunkName: "promoter.reports" */ './views/dashboard/all-sales.vue'),
        meta: {
          title: 'Customer Order History',
          icon: 'mdi-sale',
          breadcrumbs: [
            {
              text: 'sales-reports',
              disabled: false,
              to: '/promoter/sales-reports',
              exact: true,
            },
          ],
        },
      },
    ],
    meta: {
      auth: 'promoter',
    },
  },
];
