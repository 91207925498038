import Api from './api.service';
export default {
  get(params) {
    return Api().get('/event-types', { params });
  },
  getById(id) {
    return Api().get(`/event-types/${id}`);
  },
  update(data) {
    return Api().put(`/event-types/${data._id}`, data);
  },
  save(data) {
    return Api().post('/event-types', data);
  },
  delete(id) {
    return Api().delete(`/event-types/${id}`);
  },
  search(name) {
    return Api().get(`/event-types/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/event-types/export', {
      responseType: 'blob',
      params,
    });
  },
};
