<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
        <v-form ref="forgotPasswordForm" v-model="form" lazy-validation>
          <v-card-title>Events Count</v-card-title>
          <v-data-table
            :headers="calloverHeaders"
            item-key="_id"
            :items="calloverData"
            hide-default-footer
          >
          </v-data-table>
          <v-spacer />
          <v-card-actions>
            <div class="mx-auto">
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      calloverHeaders: [
        { text: 'Event Name', value: 'title', sortable: false, width: '150px' },
        { text: 'Uses', value: 'promocodeCount', sortable: false, width: '20px' },
        { text: 'Ticket Count', value: 'ticketCount', sortable: false, width: '100px' },
      ],
      calloverData: [],
    };
  },
  mounted() {
    this.$root.$on('count-list', (data) => {
      this.calloverData = [];
      this.calloverData = data.usageCount;
      this.dialog = true;
    });
  },
  methods: {
    onCancel() {
      this.dialog = false;
    },
  },
};
</script>
