import Api from './api.service';
export default {
  get(params) {
    if (params.status === 'all') {
      params.status = null;
    }
    return Api().get('/addons', { params });
  },
  getById(id) {
    return Api().get(`/addons/${id}`);
  },
  save(data) {
    return Api().post('/addons', data);
  },
  update(data) {
    return Api().put(`/addons/${data._id}`, data);
  },
  checkCount(data) {
    return Api().put(`/addons/check-count`, data);
  },
  releaseTicket(data) {
    return Api().put(`/addons/release-ticket`, data);
  },
  checkDuplicate(text, params) {
    return Api().get(`/addons/duplicate/${text}`, { params });
  },
  getAddonSales(addonId) {
    return Api().get(`/addons/sales/${addonId}`);
  },
  delete(id) {
    return Api().delete(`/addons/${id}`);
  },
  exportExcel(params) {
    return Api().get('/addons/export', {
      responseType: 'blob',
      params,
    });
  },
  saveEventAddson(data) {
    return Api().post('/addons/events', data);
  },
  getEventAddson(addonId, value, eventId) {
    return Api().get(`/addons/events/${addonId}/${value}/${eventId}`);
  }
};
