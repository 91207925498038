import Api from './api.service';

export default {
  register(params) {
    return Api().post('user/register', params);
  },
  promoterRegister(params) {
    return Api().post('/users', params);
  },
  login(params) {
    return Api().post('/auth/local', params);
  },
  socialLogin(params){
    return Api().post('/auth/social', params);
  },
  verify(params) {
    return Api().post('/auth/verify-email', params);
  },
  resend(params) {
    return Api().post('/auth/resend-verification-code', params);
  },
  changePassword(params) {
    return Api().patch('change-password', params);
  },
  forgotPassword(params) {
    return Api().post('/auth/forgot-password', params);
  },
  resetPassword(params) {
    return Api().post('/auth/reset-password', params);
  },
  verifyEmail(params) {
    return Api().post('verify/email', params);
  },
  checkEmail(data) {
    return Api().post('/auth/check-email', data);
  },
  resendVerifyEmail(params) {
    return Api().post('resend/email', params);
  },
  logout() {
    return Api({ hideErrorMessage: true }).post('logout');
  },
  delete() {
    return Api().delete('user');
  },
};
