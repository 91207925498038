import Api from './api.service';
export default {
  get(params) {
    return Api().get('/sub-categories', { params });
  },
  getById(id) {
    return Api().get(`/sub-categories/${id}`);
  },
  getByGenreId(genreId) {
    return Api().get(`/sub-categories`, {
      params: {
        genre: genreId,
        limit: 'all',
      },
    });
  },
  update(data) {
    return Api().put(`/sub-categories/${data._id}`, data);
  },
  save(data) {
    return Api().post('/sub-categories', data);
  },
  checkDuplicate(text, params) {
    return Api().get(`/sub-categories/duplicate/${text}`, { params });
  },
  delete(id) {
    return Api().delete(`/sub-categories/${id}`);
  },
  search(name) {
    return Api().get(`/sub-categories/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/sub-categories/export', {
      responseType: 'blob',
      params,
    });
  },
};
