import { PaymentService, UserService } from '@services';

export default {
  data() {
    return { data: {}, mounted: false };
  },
  async mounted() {
    //const accountId = localStorage.getItem('account_id');
    await this.getStripeLink();
    //console.log("accountId",accountId)
    //await this.getAccountInfo(accountId);
    this.mounted = true;
  },
  methods: {
    async getStripeLink() {
      let user = await UserService.me();
      const response = await PaymentService.getStripeLink(user.stripe.accountId);
      if (response) {
        this.data = response;
      }
    },
    async getAccountInfo(id) {
      const response = await PaymentService.getAccountInfo(id);
      if (response) {
        this.data = response;
      }
    }
  },
};