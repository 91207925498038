import { make } from 'vuex-pathify';

const state = {
  loading: false,
  state: {},
};

const mutations = make.mutations(state);

const actions = make.actions('items');

const getters = make.getters(state);

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
