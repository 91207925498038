<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
        <v-form ref="forgotPasswordForm" v-model="form" lazy-validation>
          <v-card-title>Performers</v-card-title>

          <v-list-item v-for="item in performers" :key="item._id">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-spacer />
          <v-card-actions>
            <div class="mx-auto">
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      event: null,
      form: false,
      loading: false,
      performers: [],
    };
  },
  mounted() {
    this.$root.$on('performers-list', performers => {
      this.performers = performers;
      this.dialog = true;
    });
  },
  methods: {
    onCancel() {
      this.dialog = false;
    },
  },
};
</script>
