import Api from './api.service';
export default {
  me() {
    return Api().get('/users/me');
  },
  getMyOrders(params) {
    return Api().get('/users/me/order', { params});
  },
  getLikedEvents(params) {
    return Api().get('/users/me/liked-events', { params});
  },
  getById(id) {
    return Api().get(`/users/${id}`);
  },
  update(id, data) {
    return Api().put(`/users/${id}`, data);
  },
  deleteUser(id) {
    return Api().delete(`/users/${id}`);
  },
  editpassword(id, params) {
    return Api().put(`/users/${id}/password`, params);
  },
  getOrganiserInfo(id) {
    return Api().get(`/organisers/${id}`);
  },
  editinfo(id, params) {
    return Api().put(`/organisers/${id}`, params);
  },
  savePayout(data) {
    return Api().post(`/organisers/update-stripe-account`, data);
  },
  checkpayment() {
    return Api().get('/organisers/get-stripe-account?limit=10');
  },
  deletePayout() {
    return Api().delete('/organisers/delete-stripe-account');
  },
  save(user) {
    return Api().post(`/users/create`, user);
  },
  createTemporaryUser() {
    return Api().post(`/users/create-temporary`);
  },
  getAll(params) {
    return Api().get('/users', { params });
  },
  exportExcel(params) {
    return Api().get('/users/export', {
      responseType: 'blob',
      params,
    });
  },
  downloadICSFile(params) {
    return Api().get('/users/downloadICS', {
      responseType: 'blob',
      params,
    });
  }
};
