import Api from './api.service';
export default {
  get(params) {
    return Api().get('/subscriptions', { params });
  },
  exportExcel(params) {
    return Api().get('/subscriptions/export', {
      responseType: 'blob',
      params,
    });
  },
};
