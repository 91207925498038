import Api from './api.service';
export default {
  get(params) {
    return Api().get('/third-party-users', { params });
  },
  getById(id) {
    return Api().get(`/third-party-users/${id}`);
  },
  update(data) {
    return Api().put(`/third-party-users/${data._id}`, data);
  },
  save(data) {
    return Api().post('/third-party-users', data);
  },
  delete(id) {
    return Api().delete(`/third-party-users/${id}`);
  },
  checkDuplicate(text) {
    return Api().get(`/third-party-users/duplicate/${text}`);
  },
  checkDuplicateEmail(text) {
    return Api().get(`/third-party-users/check-email/${text}`);
  },
  search(name) {
    return Api().get(`/third-party-users/search/${name}`);
  },
  exportExcel(params) {
    return Api().get('/third-party-users/export', {
      responseType: 'blob',
      params,
    });
  },
};
